import { ViewEncapsulation, ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, ErrorHandler, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import { AbstractControl, Validators, ValidationErrors, FormControl, FormGroup, ValidatorFn } from '@angular/forms'

import { MatSnackBar } from '@angular/material/snack-bar'

import { KirjanpitajanNimitiedot } from '../_jaettu-lemonator/model/kirjanpitaja'
import { KirjanpitoraporttienLahetystiedot, AlvIlmoitusjakso, Asiakas } from '../_jaettu-lemonator/model/asiakas'
import { KirjanpitoEmailLahetysTyojono, KirjanpitoEmailLahetys, KirjanpitoEmailLahetysHistoryPdfRequest, AlvIlmoitus, KirjanpitoEmailTextAutosaveData, EmailLiite, EmailLiiteDownloadRequest, RaporttiRequest, RaporttiPdfResponse, RaporttiName, Raportointikirjaus } from '../_jaettu-lemonator/model/kirjanpito'
import { RaporttiType } from 'app/_jaettu/model/reports-shared'
import { LocalMonth, LocalDate, TuettuKieli, NumberDate } from '../_shared-core/model/common'
import { EmailLahetysStatusKoodi, EmailLahetysStatus } from '../_jaettu/model/lasku'

import { AsiakasService, AsiakkaanAvainTiedot } from '../_angular/service/asiakas/asiakas.service'
import { TranslationService } from '../_jaettu/service/translation.service'
import { DateService } from '../_shared-core/service/date.service'
import { TimestampService } from '../_jaettu-angular/service/timestamp-service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { KirjanpitajanTiedot, KirjautunutKayttajaService } from '../_angular/service/kirjautunut-kayttaja.service'
import { KirjanpitoUriService } from '../_jaettu-lemonator/service/kirjanpito-uri.service'
import { KirjanpitajaService } from '../_angular/service/kirjanpitaja/kirjanpitaja.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { KirjanpitoJaettuService } from '../_jaettu-lemonator/service/kirjanpito-jaettu.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { AsiakasJaettuService } from '../_jaettu-lemonator/service/asiakas-jaettu.service'
import { Yritysmuoto } from '../_jaettu/model/kayttaja'

import { take, map, takeUntil, startWith, switchMap, tap, distinctUntilChanged, withLatestFrom, delay } from 'rxjs/operators'
import { Observable, Subject, combineLatest, of as observableOf, BehaviorSubject, firstValueFrom } from 'rxjs'

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from '../_jaettu-angular/_rxjs/lemon-share.operator'
import { FileSaverService } from 'app/_jaettu-angular/service/file-saver'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { DebugService } from 'app/_angular/service/debug.service'

interface HistoriaEmailLiite extends EmailLiite {
  firestoreLinkki?: string
  firestoreLinkkiEncoded?: string
}

interface LahetysHistoria extends KirjanpitoEmailLahetys {
  recipientsEmails: string
  liitteet: HistoriaEmailLiite[]
  nimi: string
  luotuDateStr: string
  firestoreLinkki?: string
  firestoreLinkkiEncoded?: string
}

interface LahetysForm {
  aihe: FormControl<string>
  tekstinAlku: FormControl<string>
  tekstinLoppu: FormControl<string>
  tuloslaskelma: FormControl<boolean>
  tase: FormControl<boolean>
  paakirja: FormControl<boolean>
  alvIlmoitus: FormControl<boolean>
  selvitettavatOstot: FormControl<boolean>
  selvitettavatMyynnit: FormControl<boolean>
  lahettaja: FormControl<string>
  piilokopioLahettajalle: FormControl<boolean>
  vastaanottajat: FormControl<string>
}

@Component({
  selector: '[app-kirjanpito-lahetys]',
  templateUrl: './kirjanpito-lahetys.component.html',
  styleUrls: ['./kirjanpito-lahetys.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KirjanpitoLahetysComponent implements OnInit, OnDestroy {

  @Input() valittuKuukausiObservable: Observable<LocalMonth>
  @Input() latestAlvIlmoitusObservable: Observable<AlvIlmoitus>
  @Input() naytaAlvIlmoitusValilehtiObservable: Observable<boolean>

  @Output() onkoSpostejaLahetetty: EventEmitter<boolean> = new EventEmitter()

  // @ViewChild('tekstiTextareaLoppu', { read: MatInput, static: true }) tekstiTextareaLoppu: MatInput

  lahetysForm: FormGroup<LahetysForm>
  sentThisMonth: LahetysHistoria[]
  // erapaivaObservable: Observable<LocalDate>
  nykyisenKirjanpitajanNimitiedotObservable: Observable<KirjanpitajanNimitiedot>
  attachmentsTotalSizeIfTooBigObservable: Observable<number>
  clientTypeObservable: Observable<'holvi' | 'regular' | 'unknown'>
  emailLiitteetObservable: Observable<EmailLiite[]>
  vastaanottajat: string[] = []
  historyLoading: boolean = true
  latausVirhe: string = null
  namename = 'toiughnwro' + Math.random()

  private _sendingInProgress: boolean
  private _asiakasObservable: Observable<Asiakas>
  private _lahetysAvainSubject: BehaviorSubject<string> = new BehaviorSubject(null)

  private _ngUnsubscribe = new Subject<void>()

  includeMainAutotext: boolean = true
  includeSelvitettavatAutotext: boolean = true
  mainAutotext: string
  selvitettavatAutotext: string
  autosaveTimeTextObservable: Observable<string>
  asiakasAvain: string
  asiakkaanKieliObservable: Observable<TuettuKieli>

  constructor(
    private _asiakasService: AsiakasService,
    private _asiakasJaettuService: AsiakasJaettuService,
    private _translationService: TranslationService,
    private _dateService: DateService,
    private _firebaseLemonator: FirebaseLemonator,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _timestampService: TimestampService,
    private _ladataanService: LadataanService,
    private _kayttajaService: KirjautunutKayttajaService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _kirjanpitoUriService: KirjanpitoUriService,
    private _kirjanpitajaService: KirjanpitajaService,
    private _currencyService: CurrencyService,
    private _kirjanpitoJaettuService: KirjanpitoJaettuService,
    private _formValidationService: FormValidationService,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _fileSaverService: FileSaverService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _debugService: DebugService
  ) {
    this.nykyisenKirjanpitajanNimitiedotObservable = combineLatest([this._asiakasService.nykyinenAsiakasObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable, this._kayttajaService.kirjanpitajanTiedotObservable]).pipe(
      map(([asiakas, kirjanpitajatMap, kirjanpitaja]) => {
        this.asiakasAvain = asiakas?.avain || null
        if (!kirjanpitajatMap || !kirjanpitaja) {
          return null
        }
        if (asiakas.kasittelija === 'QgPvtcCjoOdf6Zg7lgMwqLWp2BG2') { // Holvi-asiakas
          return kirjanpitajatMap.get('QgPvtcCjoOdf6Zg7lgMwqLWp2BG2')
        }
        return kirjanpitajatMap.get(kirjanpitaja.uid)
      })
    )
  }

  public focus() {
    // setTimeout(() => {
    //   this.tekstiTextareaLoppu.focus()
    // }, 50)
  }

  ngOnInit() {

    this.lahetysForm = new FormGroup<LahetysForm>({
      aihe: new FormControl<string>(null),
      tekstinAlku: new FormControl<string>(null),
      tekstinLoppu: new FormControl<string>(null),
      tuloslaskelma: new FormControl<boolean>(false),
      tase: new FormControl<boolean>(false),
      paakirja: new FormControl<boolean>(false),
      alvIlmoitus: new FormControl<boolean>(false),
      selvitettavatOstot: new FormControl<boolean>(false),
      selvitettavatMyynnit: new FormControl<boolean>(false),
      lahettaja: new FormControl<string>({ value: null, disabled: true }),
      vastaanottajat: new FormControl<string>({ value: null, disabled: true }),
      piilokopioLahettajalle: new FormControl<boolean>(false)
    })

    this._asiakasObservable = this._asiakasService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => {
        return a?.avain === b?.avain
      })
    )

    this.asiakkaanKieliObservable = this._asiakasObservable.pipe(
      map(asiakas => asiakas.laskunKieli || 'fi')
    )

    const tunnistetutYhteyshenkilot = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => {
        if (!kayttajat?.length) {
          return []
        }
        return kayttajat.filter(k => k.aktiivinen && k.roolit.HALLINTO_YHTEYSHENKILO && k.kayttajaTunnistettu === 'tunnistettu-nets')
      })
    )

    combineLatest([tunnistetutYhteyshenkilot, this._asiakasObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([yhteyshenkilot, asiakas]) => {
      this.vastaanottajat = yhteyshenkilot.map(yh => yh.email)
      if (asiakas.kirjanpitoviestienLisavastaanottajat) {
        this.vastaanottajat.push(...asiakas.kirjanpitoviestienLisavastaanottajat)
      }
      this.lahetysForm.get('vastaanottajat').setValue(this.vastaanottajat.join(', '))
    })

    this.piilokopioLahettajalle.valueChanges.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ piilokopioValittu: !!valittu }, kuukausi, asiakas)
    })
    this.paakirja.valueChanges.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ paakirjaValittu: !!valittu }, kuukausi, asiakas)
    })
    this.tase.valueChanges.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ taseValittu: !!valittu }, kuukausi, asiakas)
    })
    this.tuloslaskelma.valueChanges.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ tulosValittu: !!valittu }, kuukausi, asiakas)
    })
    this.alvIlmoitus.valueChanges.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ alvValittu: !!valittu }, kuukausi, asiakas)
    })
    this.selvitettavatOstot.valueChanges.pipe(
      distinctUntilChanged(),
      delay(2000), // Delay to not override first load of KirjanpitoEmailTextAutosaveData
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ selvOstotValittu: !!valittu }, kuukausi, asiakas)
    })
    this.selvitettavatMyynnit.valueChanges.pipe(
      distinctUntilChanged(),
      delay(2000), // Delay to not override first load of KirjanpitoEmailTextAutosaveData
      withLatestFrom(this.valittuKuukausiObservable, this._asiakasObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([valittu, kuukausi, asiakas]) => {
      this._updateAutosaveData({ selvMyynnitValittu: !!valittu }, kuukausi, asiakas)
    })

    this.clientTypeObservable = this._asiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas) {
          return 'unknown'
        }
        return this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
      })
    )

    const senderEmailObservable: Observable<string> = this._asiakasObservable.pipe(
      switchMap(asiakas => {
        if (!asiakas) {
          return observableOf<string>(null)
        }
        const tyyppi = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
        if (tyyppi === 'holvi') {
          return observableOf<string>('no-reply@lemontree.fi')
        }
        return this._kirjanpitajaService.kirjautuneenKayttajanKirjanpitajaObservable.pipe(
          map(kirjanpitaja => kirjanpitaja?.email || null)
        )
      })
    )


    senderEmailObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(email => {
      this.lahetysForm.get('lahettaja').setValue(email)
    })

    const annaSelvitettavienAikajakso: Observable<{ alku: NumberDate, loppu: NumberDate }> = this._asiakasObservable.pipe(
      map(asiakas => {
        if (!asiakas?.tilikaudet?.length) {
          return null
        }
        return this._asiakasJaettuService.annaAikajaksoSelvitettavatRaporttiaVarten(asiakas.tilikaudet)
      }),
      distinctUntilChanged((prev, current) => {
        if (prev && !current) {
          return false
        } else if (!prev && current) {
          return false
        } else if (!prev && !current) {
          return true
        }
        if (prev.alku !== current.alku || prev.loppu !== current.loppu) {
          return false
        }
        return true
      })
    )

    const selvitettavatOstotRaakadataObservable: Observable<Raportointikirjaus[]> = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, annaSelvitettavienAikajakso]).pipe(
      switchMap(([asiakas, aikajakso]) => {
        if (!asiakas || !aikajakso) {
          return observableOf<Raportointikirjaus[]>([])
        }
        const collectionUri = this._kirjanpitoUriService.annaRaporttikirjausCollectionUri(asiakas.avain)
        return this._firebaseLemonator.firestoreCollection<Raportointikirjaus>(collectionUri)
          .where('a', 'array-contains-any', ['1777'])
          .where('p', '<', aikajakso.loppu + 1)
          .where('p', '>', aikajakso.alku - 1)
          .orderBy('p', 'desc')
          .limit(1)
          .listen()

      })
    )

    const selvitettavatMyynnitRaakadataObservable: Observable<Raportointikirjaus[]> = combineLatest([this._asiakasService.nykyinenAsiakasAvainObservable, annaSelvitettavienAikajakso]).pipe(
      switchMap(([asiakas, aikajakso]) => {
        if (!asiakas || !aikajakso) {
          return observableOf<Raportointikirjaus[]>([])
        }
        const collectionUri = this._kirjanpitoUriService.annaRaporttikirjausCollectionUri(asiakas.avain)
        return this._firebaseLemonator.firestoreCollection<Raportointikirjaus>(collectionUri)
          .where('a', 'array-contains-any', ['3099'])
          .where('p', '<', aikajakso.loppu + 1)
          .where('p', '>', aikajakso.alku - 1)
          .orderBy('p', 'desc')
          .limit(1)
          .listen()
      })
    )

    selvitettavatOstotRaakadataObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(selvitettavatOstotRawData => {
      if (selvitettavatOstotRawData?.length > 0) {
        this.selvitettavatOstot.enable()
        this.selvitettavatOstot.setValue(true)
      } else {
        this.selvitettavatOstot.setValue(false)
        this.selvitettavatOstot.disable()
      }
    })

    selvitettavatMyynnitRaakadataObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(selvitettavatMyynnitRawData => {
      if (selvitettavatMyynnitRawData?.length > 0) {
        this.selvitettavatMyynnit.enable()
        this.selvitettavatMyynnit.setValue(true)
      } else {
        this.selvitettavatMyynnit.setValue(false)
        this.selvitettavatMyynnit.disable()
      }
    })

    const raportitFormsObservable: Observable<string[]> = combineLatest([
      this._asiakasObservable,
      this.tuloslaskelma.valueChanges.pipe(startWith(false)),
      this.tase.valueChanges.pipe(startWith(false)),
      this.paakirja.valueChanges.pipe(startWith(false)),
      this.alvIlmoitus.valueChanges.pipe(startWith(false))
    ]).pipe(
      map(([asiakas, tuloslaskelma, tase, paakirja, alv]) => {
        const kieli = asiakas.laskunKieli || 'fi'
        const namesOfSelected: string[] = []
        if (tuloslaskelma) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys.tuloslaskelma', kieli)) }
        if (tase) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys.tase', kieli)) }
        if (paakirja) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys.paakirja', kieli)) }
        if (alv) { namesOfSelected.push(this._translationService.lokalisoi('kirjanpito-lahetys.alv-ilmoitus', kieli)) }
        return namesOfSelected
      }),
      distinctUntilChanged((a, b) => {
        return JSON.stringify(a) === JSON.stringify(b)
      })
    )

    this.emailLiitteetObservable = this._lahetysAvainSubject.asObservable().pipe(
      withLatestFrom(this._asiakasObservable),
      switchMap(([lahetysAvain, asiakas]) => {
        if (!asiakas) {
          return observableOf<EmailLiite[]>([])
        }
        const uri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksenLiitteetCollection(asiakas.avain, lahetysAvain)
        return this._firebaseLemonator.firestoreCollection<EmailLiite>(uri)
          .where('poistettu', '==', false)
          .listen()
      }),
      takeUntil(this._ngUnsubscribe)
    )

    this.attachmentsTotalSizeIfTooBigObservable = combineLatest([
      this.tuloslaskelma.valueChanges.pipe(startWith(false)),
      this.tase.valueChanges.pipe(startWith(false)),
      this.paakirja.valueChanges.pipe(startWith(false)),
      this.alvIlmoitus.valueChanges.pipe(startWith(false)),
      this.emailLiitteetObservable.pipe(startWith([] as EmailLiite[]))
    ]).pipe(
      map(([tuloslaskelma, tase, paakirja, alv, liitteet]) => {
        let totalKoko = 0
        const averageReportSize = 100 * 1000 // 100 KB
        if (tuloslaskelma) { totalKoko += averageReportSize }
        if (tase) { totalKoko += averageReportSize }
        if (paakirja) { totalKoko += averageReportSize }
        if (alv) { totalKoko += averageReportSize }

        for (const liite of (liitteet || [])) {
          totalKoko += liite.koko || 512 * 1000 // 512 KB
        }

        if (totalKoko > Math.pow(10, 7)) {
          return this._currencyService.roundHalfUp(totalKoko / Math.pow(10, 6), 1)
        }
        return 0
      }),
      takeUntil(this._ngUnsubscribe)
    )

    // this.erapaivaObservable = combineLatest([this.valittuKuukausiObservable, this._asiakasObservable]).pipe(
    //   map(([kuukausi, asiakas]) => {
    //     if (!kuukausi || !asiakas) {
    //       return null
    //     }
    //     const kuunEnsimmainen: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
    //     const nykyinenIlmoitusjakso = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, this._dateService.localDateToDate(kuunEnsimmainen))
    //     const seuraavaIlmoitusjakso = this._asiakasJaettuService.annaSeuraavaAlvIlmoitusjakso(asiakas, nykyinenIlmoitusjakso)
    //     const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
    //     return this._kirjanpitoJaettuService.annaAlvnErapaiva(clientType, kuukausi, nykyinenIlmoitusjakso, seuraavaIlmoitusjakso)
    //   }),
    //   distinctUntilChanged((a, b) => {
    //     return this._dateService.localDateToNumber(a) === this._dateService.localDateToNumber(b)
    //   })
    // )

    const rawSavedTextsInDatabaseObservable: Observable<Partial<KirjanpitoEmailTextAutosaveData>> = combineLatest([
      this.valittuKuukausiObservable,
      this._asiakasObservable
    ]).pipe(
      switchMap(([kuukausi, asiakas]) => {
        this.aihe.setValue('')
        this.tekstinAlku.setValue('')
        this.tekstinLoppu.setValue('')

        if (!kuukausi || !asiakas) {
          return observableOf<KirjanpitoEmailTextAutosaveData>(null)
        }

        const kieli = asiakas.laskunKieli || 'fi'
        const autosaveDoc = this._firebaseLemonator.firestoreDoc<KirjanpitoEmailTextAutosaveData>(this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakas.avain, kuukausi))
        return autosaveDoc.listen().pipe(
          withLatestFrom(this._kayttajaService.kirjanpitajanTiedotObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable),
          map(([autosaved, kirjanpitajanTiedot, nimitiedotMap]) => {
            const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
            const newText: Partial<KirjanpitoEmailTextAutosaveData> = {
              aihe: this._annaAihe(autosaved, kuukausi, asiakas, kieli),
              tekstinAlku: this._getTextStartFromAutosaveData(autosaved, kieli, clientType),
              tekstinLoppu: this._getTextEndFromAutosaveData(autosaved, kieli, clientType, kirjanpitajanTiedot, nimitiedotMap),
              paivittaja: autosaved?.paivittaja || null,
              paivitettu: autosaved?.paivitettu || null
            }
            return newText
          })
        )

      }),
      lemonShare()
    )

    this.autosaveTimeTextObservable = combineLatest([rawSavedTextsInDatabaseObservable, this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable]).pipe(
      map(([dbTextDocument, nimitiedotMap]) => {
        if (dbTextDocument?.paivitettu) {
          if (nimitiedotMap) {
            const kirjanpitaja = nimitiedotMap.get(dbTextDocument.paivittaja)
            if (kirjanpitaja) {
              return 'Tallentanut ' + kirjanpitaja.etunimi + ' ' + kirjanpitaja.sukunimi + ' ' + this._dateService.muotoilePaivaJaAikaDate(dbTextDocument.paivitettu.toDate(), 'fi')
            }
          }
          return 'Tallennettu ' + this._dateService.muotoilePaivaJaAikaDate(dbTextDocument.paivitettu.toDate(), 'fi')
        }
        return ''
      })
    )

    rawSavedTextsInDatabaseObservable.pipe(
      takeUntil(this._ngUnsubscribe),
    ).subscribe(texts => {

      if (!this.aihe.value?.trim()) {
        this.aihe.setValue(texts.aihe)
      }
      if (!this.tekstinAlku.value) { // Note: Don't include trim() to allow emptying text boxes
        this.tekstinAlku.setValue(texts.tekstinAlku)
      }
      if (!this.tekstinLoppu.value) { // Note: Don't include trim() to allow emptying text boxes
        this.tekstinLoppu.setValue(texts.tekstinLoppu)
      }
    })

    const nonVatAutotextObservable = combineLatest([
      raportitFormsObservable,
      this.valittuKuukausiObservable,
      this._asiakasObservable
    ]).pipe(
      map(([selectedLiitteet, kuukausi, asiakas]) => {

        if (!kuukausi || !asiakas) {
          return null
        }

        const kieli = asiakas.laskunKieli || 'fi'
        const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
        const kuukaudenNimi = this._dateService.annaKuukaudenNimiPaikallinen(kuukausi, kieli)

        // Format liitteet list (final two should have ' ja ' between them)
        const liitteetFormatted: string = this._createLiitteetListText(selectedLiitteet, kieli, clientType)

        // Text for non-VAT clients
        const nonVatMailText = 'kirjanpito-lahetys.' + clientType + '.mail-text-non-alv-client'
        // Return text for non-VAT client
        const nonVatClientTeksti = this._translationService.lokalisoi(nonVatMailText, kieli, {
          kuukaudenNimi: kuukaudenNimi,
          liitteetText: liitteetFormatted
        })
        return nonVatClientTeksti
      })
    )

    const apixSettingsObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(asiakas => {
        if (asiakas?.avain) {
          return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + asiakas.avain + '/apix-received-invoice-config/' + asiakas.avain).listen()
        }
        return observableOf<ApixReceivedInvoiceConfig>(null)
      })
    )

    const vatAutotextObservable = combineLatest([
      raportitFormsObservable,
      this.valittuKuukausiObservable,
      this._asiakasObservable,
      this.latestAlvIlmoitusObservable,
      this.naytaAlvIlmoitusValilehtiObservable,
      apixSettingsObservable
    ]).pipe(
      map(([selectedLiitteet, kuukausi, asiakas, alvIlmoitus, onkoVerokausi, apixSettings]) => {

        if (!kuukausi || !asiakas || !alvIlmoitus || onkoVerokausi === undefined) {
          return null
        }

        const kieli = asiakas.laskunKieli || 'fi'
        const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
        const kuukaudenNimi = this._dateService.annaKuukaudenNimiPaikallinen(kuukausi, kieli)

        // Format liitteet list (final two should have ' ja ' between them)
        const liitteetFormatted: string = this._createLiitteetListText(selectedLiitteet, kieli, clientType)

        const kuunEnsimmainen: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
        const nykyinenIlmoitusjakso = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalle(asiakas, this._dateService.localDateToDate(kuunEnsimmainen))
        const seuraavaIlmoitusjakso = this._asiakasJaettuService.annaSeuraavaAlvIlmoitusjakso(asiakas, nykyinenIlmoitusjakso)

        // Standard text for clients with monthly VAT-period set as default
        let mailTextRoot = 'kirjanpito-lahetys.' + clientType + '.mail-text-monthly-alv-jakso.'
        if (nykyinenIlmoitusjakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK3 || nykyinenIlmoitusjakso.alvIlmoitusjakso === AlvIlmoitusjakso.KK12) {
          // Set text by whether the chosen month is the final month of the VAT period or not
          mailTextRoot = 'kirjanpito-lahetys.' + clientType + (onkoVerokausi ? '.mail-text-alv-month.' : '.mail-text-not-alv-month.')
        }

        const alvSum = this._getAlvSum(alvIlmoitus)
        const vatMailText: string = mailTextRoot + this._getTextFromAlvSum(alvSum)

        const dueDate = this._kirjanpitoJaettuService.annaAlvnErapaiva(clientType, kuukausi, nykyinenIlmoitusjakso, seuraavaIlmoitusjakso)
        const weekDayName = this._dateService.annaPaivanNimiPaikallinen(dueDate, kieli).toLowerCase()
        const dueDateStr = this._dateService.muotoilePaikallinenPaiva(dueDate, kieli)

        // Return text for VAT client
        const vatClientTeksti = this._translationService.lokalisoi(vatMailText, kieli, {
          kuukaudenNimi: kuukaudenNimi,
          liitteetText: liitteetFormatted,
          alvSum: this._currencyService.formatoiRahaIlmanValuuttaSymbolia((alvSum > 0 ? alvSum : -1 * alvSum), kieli),
          weekdayOfDueDate: weekDayName,
          dueDate: dueDateStr
        })

        // If there is VAT, add text to reflect that
        if (
          alvSum &&
          alvSum > 0 &&
          !asiakas.estaAlvVerkkolaskujenLahetys &&
          onkoVerokausi
        ) {

          if (
            (
              (
                asiakas.sahkoinenLaskutusosoite?.sahkoinenOsoite &&
                asiakas.sahkoinenLaskutusosoite?.sahkoinenValittaja
              ) ||
              (
                apixSettings?.forwardActive &&
                apixSettings?.forwardAddress?.sahkoinenOsoite &&
                apixSettings?.forwardAddress?.sahkoinenValittaja &&
                apixSettings?.forwardLemontreeLaskut
              )
            ) &&
            !apixSettings?.paymentReceiveIsActive
          ) {
            const dateToCompareWith = alvIlmoitus.lahetetty ? this._dateService.timestampToLocalDate(alvIlmoitus.lahetetty) : this._dateService.currentLocalDate()

            let dueDateWarningText = null
            if (this._dateService.paiviaValissaPaikallinen(dueDate, dateToCompareWith) < 5) {
              dueDateWarningText = '\n' + this._translationService.lokalisoi('kirjanpito-lahetys.teknisista-syista-erapaiva', kieli)
            }
            return vatClientTeksti + '\n' + this._translationService.lokalisoi('kirjanpito-lahetys.alv-verkkolasku-lahetetty', kieli) + (dueDateWarningText ?? '')
          } else if (apixSettings?.paymentReceiveIsActive && apixSettings?.forwardLemontreeLaskut) {
            return vatClientTeksti + '\n' + this._translationService.lokalisoi('kirjanpito-lahetys.alv-verkkolasku-lahetetty-payments', kieli)
          }

        }

        return vatClientTeksti

      })
    )

    const mainAutotextObservable = combineLatest([
      this.valittuKuukausiObservable,
      this._asiakasObservable
    ]).pipe(
      switchMap(([kuukausi, asiakas]) => {
        const kuunEnsimmainen: LocalDate = { year: kuukausi.year, month: kuukausi.month, day: 1 }
        const nykyinenIlmoitusjakso = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalleIlmanObjektia(asiakas, this._dateService.localDateToDate(kuunEnsimmainen))
        if (nykyinenIlmoitusjakso === AlvIlmoitusjakso.EI || nykyinenIlmoitusjakso === AlvIlmoitusjakso.TUNTEMATON) {
          // console.log('Return NON-VAT')
          return nonVatAutotextObservable
        }
        // console.log('Return VAT')
        return vatAutotextObservable
      })
    )

    mainAutotextObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(text => {
      this.mainAutotext = text
      this._changeDetectorRef.markForCheck()
      this._changeDetectorRef.detectChanges()
    })

    combineLatest([this.valittuKuukausiObservable, this._asiakasObservable]).pipe(
      switchMap(([kuukausi, asiakas]) => {
        if (!kuukausi || !asiakas) {
          return observableOf<KirjanpitoEmailTextAutosaveData>(null)
        }
        const uri = this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakas.avain, kuukausi)
        return this._firebaseLemonator.firestoreDoc<KirjanpitoEmailTextAutosaveData>(uri).listen().pipe(
          take(1),
          map(autosaveData => {

            const output: Partial<KirjanpitoEmailTextAutosaveData> = {}

            output.lahetysAvain = autosaveData?.lahetysAvain

            // If regular client: piilokopio turned on by default and then set according to autosaved value
            // If Holvi client: piilokopio is always turned off
            const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)
            if (clientType === 'holvi') {
              output.piilokopioValittu = false
              this.piilokopioLahettajalle.disable()
            } else {
              this.piilokopioLahettajalle.enable()
              output.piilokopioValittu = autosaveData?.piilokopioValittu ?? true
            }

            const date = this._dateService.localDateToDate({ year: kuukausi.year, month: kuukausi.month, day: 1 })
            const alvKausi = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalleIlmanObjektia(asiakas, date)
            const alvDisabled = alvKausi === AlvIlmoitusjakso.EI || alvKausi === AlvIlmoitusjakso.TUNTEMATON
            if (alvDisabled) {
              output.alvValittu = false
              this.alvIlmoitus.setValue(false)
              this.alvIlmoitus.disable()
            } else {
              this.alvIlmoitus.enable()
            }

            const autosaveHasCheckboxData = this._doesAutosaveHaveCheckboxData(autosaveData)
            if (autosaveHasCheckboxData) {
              output.paakirjaValittu = autosaveData.paakirjaValittu
              output.taseValittu = autosaveData.taseValittu
              output.tulosValittu = autosaveData.tulosValittu
              output.alvValittu = alvDisabled ? false : autosaveData.alvValittu
              output.selvOstotValittu = autosaveData.selvOstotValittu
              output.selvMyynnitValittu = autosaveData.selvMyynnitValittu
              return output
            }

            output.paakirjaValittu = asiakas.kirjanpitoraporttienLahetystiedot?.liitaPaakirja ?? false
            output.tulosValittu = clientType === 'holvi' ? (asiakas.kirjanpitoraporttienLahetystiedot?.liitaTuloslaskelma ?? false) : true
            output.taseValittu = clientType === 'holvi' ? (asiakas.kirjanpitoraporttienLahetystiedot?.liitaTase ?? false) : true
            output.alvValittu = alvDisabled ? false : true

            return output
          })
        )
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(autosaved => {
      this._lahetysAvainSubject.next(autosaved.lahetysAvain || this._firebaseLemonator.firestoreCreateId())
      this._setCheckboxesFromAutosave(autosaved)
    })

    combineLatest([
      this._asiakasObservable,
      this.selvitettavatOstot.valueChanges,
      this.selvitettavatMyynnit.valueChanges
    ]).pipe(
      map(([asiakas, ostotValittu, myynnitValittu]) => {
        if (!asiakas) {
          return ''
        }
        return this._getSelvitettavatText(asiakas, ostotValittu, myynnitValittu)
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(text => {
      this.selvitettavatAutotext = text
    })

    combineLatest([
      this._asiakasService.nykyinenAsiakasAvainObservable,
      this.valittuKuukausiObservable,
      this._kirjanpitajaService.kirjanpitajienNimitiedotMapObservable,
      this._kayttajaService.kirjanpitajaOnDevaajaObservable
    ]).pipe(
      switchMap(([asiakas, selectedMonth, kirjanpitajatMap, onkoDevaaja]) => {
        if (asiakas && selectedMonth) {
          this.historyLoading = true
          const uri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksetCollection(asiakas.avain)
          return this._firebaseLemonator.firestoreCollection<KirjanpitoEmailLahetys>(uri)
            .where('tunniste', '==', selectedMonth.month + '_' + selectedMonth.year)
            .listen().pipe(
              map(lahetykset => {
                const sortedLahetykset = lahetykset.sort((a, b) => b.luotu.toMillis() - a.luotu.toMillis())
                return sortedLahetykset.map(lahetys => {
                  const kirjanpitajaData = kirjanpitajatMap.get(lahetys.luoja)
                  const historyEntry: LahetysHistoria = Object.assign({}, lahetys,
                    {
                      recipientsEmails: lahetys.recipients.reduce((a, b) => a + b.email + ' ', ''),
                      liitteet: [],
                      nimi: kirjanpitajaData.etunimi + ' ' + kirjanpitajaData.sukunimi,
                      luotuDateStr: this._dateService.muotoilePaivaJaAikaDate(this._dateService.timestampToDate(lahetys.luotu), 'fi'),
                    })
                  if (onkoDevaaja) {
                    // Debug thingies
                    const debugUri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksetLahetysAvaimella(asiakas.avain, historyEntry.avain)
                    historyEntry.firestoreLinkki = debugUri
                    historyEntry.firestoreLinkkiEncoded = this._debugService.createFirestoreLink(debugUri)
                  }
                  // historyEntry.teksti = historyEntry.teksti.replace(/<br\s*\/?>/gi, '\n')
                  return historyEntry
                })
              })
            )
        }
        return observableOf<LahetysHistoria[]>([])
      }),
      tap(data => {
        this.onkoSpostejaLahetetty.emit(data && data.length > 0)
      }),
      withLatestFrom(this._asiakasService.nykyinenAsiakasAvainObservable, this._kayttajaService.kirjanpitajaOnDevaajaObservable),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([lahetysHistoriat, asiakasAvaintiedot, onkoDevaaja]) => {
      if (!lahetysHistoriat?.length) {
        this.sentThisMonth = []
        this.historyLoading = false
        return
      }
      const liitteetPromises: Promise<void>[] = []
      for (const historia of lahetysHistoriat) {
        const promise = this._annaLahetyksenLiitteet(asiakasAvaintiedot, historia, onkoDevaaja)
        liitteetPromises.push(promise)
      }
      await Promise.all(liitteetPromises)

      this.sentThisMonth = lahetysHistoriat
      this.historyLoading = false
    })
  }

  private _annaLahetyksenLiitteet(asiakasAvaintiedot: AsiakkaanAvainTiedot, historia: LahetysHistoria, onkoDevaaja: boolean): Promise<void> {
    const uri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksenLiitteetCollection(asiakasAvaintiedot.avain, historia.avain)
    return this._firebaseLemonator.firestoreCollection<HistoriaEmailLiite>(uri)
      .where('poistettu', '==', false)
      .get()
      .then(liitteet => {
        historia.liitteet = liitteet || []
        if (onkoDevaaja) {
          // Debug thingies
          for (const liite of historia.liitteet) {
            const debugUri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksenLiitteenUri(asiakasAvaintiedot.avain, historia.avain, liite.avain)
            liite.firestoreLinkki = debugUri
            liite.firestoreLinkkiEncoded = this._debugService.createFirestoreLink(debugUri)
          }
        }
      })
  }

  // async reload() {
  //   const asiakas = await firstValueFrom(this._asiakasObservable)
  //   const kuukausi = await firstValueFrom(this.valittuKuukausiObservable)
  //   const uri = this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakas.avain, kuukausi)
  //   await this._firebaseLemonator.firestoreDeleteDoc(uri)
  // }

  private _annaAihe(autosaved: KirjanpitoEmailTextAutosaveData, kuukausi: LocalMonth, asiakas: Asiakas, kieli: TuettuKieli) {

    if (autosaved) {
      if (autosaved.aiheet && autosaved.aiheet[kieli]) {
        return autosaved.aiheet[kieli]
      }
      if (kieli === 'fi' && autosaved.aihe) {
        return autosaved.aihe
      }
    }

    const kuukaudenNimi = this._dateService.annaKuukaudenNimiPaikallinen(kuukausi, kieli)
    const kuukaudenNimiFormatted = kieli === 'en' ? kuukaudenNimi.substring(0, 1).toUpperCase() + kuukaudenNimi.substring(1).toLowerCase() : kuukaudenNimi.toLowerCase()
    return `${asiakas.nimi}, ${kuukaudenNimiFormatted} ${kuukausi.year}`
  }

  private _getTextStartFromAutosaveData(autosaved: KirjanpitoEmailTextAutosaveData, kieli: TuettuKieli, clientType: 'holvi' | 'regular'): string {

    if (autosaved) {
      if (autosaved.tekstinAlut && autosaved.tekstinAlut[kieli]) {
        return autosaved.tekstinAlut[kieli]
      }
      if (kieli === 'fi' && autosaved.tekstinAlku) {
        return autosaved.tekstinAlku
      }
    }

    return this._translationService.lokalisoi('kirjanpito-lahetys.' + clientType + '.input-start', kieli)

  }

  private _getTextEndFromAutosaveData(autosaved: KirjanpitoEmailTextAutosaveData, kieli: TuettuKieli, clientType: 'holvi' | 'regular', kirjanpitajanTiedot: KirjanpitajanTiedot, nimitiedotMap: Map<string, any>): string {

    if (autosaved) {
      if (autosaved.tekstinLoput && autosaved.tekstinLoput[kieli]) {
        return autosaved.tekstinLoput[kieli]
      }
      if (kieli === 'fi' && autosaved.tekstinLoppu) {
        return autosaved.tekstinLoppu
      }
    }

    const emailEndText = this._getEmailEndText(kieli, clientType, kirjanpitajanTiedot, nimitiedotMap)
    return this._translationService.lokalisoi('kirjanpito-lahetys.' + clientType + '.input-end', kieli) + (emailEndText ? ('\n' + emailEndText) : '')

  }

  private _getEmailEndText(kieli: TuettuKieli, clientType: 'holvi' | 'regular', kirjanpitajanTiedot: KirjanpitajanTiedot, nimitiedotMap: Map<string, any>): string {
    if (clientType === 'regular' && nimitiedotMap && kirjanpitajanTiedot.uid) {
      return nimitiedotMap.get(kirjanpitajanTiedot.uid)?.emailEndText?.[kieli] ?? ''
    }
    return ''
  }

  private _updateAutosaveData(data: Partial<KirjanpitoEmailTextAutosaveData>, kuukausi: LocalMonth, asiakas: Asiakas): Promise<void> {
    if (!kuukausi || !asiakas) {
      return Promise.resolve()
    }
    const uri = this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakas.avain, kuukausi)
    data.paivitettu = this._timestampService.now()
    return this._firebaseLemonator.firestoreSetData(uri, data, { merge: true })
  }

  async _resetAutosave(): Promise<void> {

    const asiakasAvainTiedotPromise = firstValueFrom(this._asiakasService.nykyinenAsiakasAvainObservable)
    const kuukausiPromise = firstValueFrom(this.valittuKuukausiObservable)

    const [asiakasAvainTiedot, kuukausi] = await Promise.all([asiakasAvainTiedotPromise, kuukausiPromise])

    const uri = this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakasAvainTiedot.avain, kuukausi)
    return this._firebaseLemonator.firestoreDeleteDoc(uri)
  }

  private _getAlvSum(latestAlv: AlvIlmoitus): number {
    if (latestAlv) {
      return this._kirjanpitoJaettuService.annaMaksettavaTaiPalautettavaVero(latestAlv)
    }
    return 0
  }

  // get vastaanottaja() {
  //   return this.lahetysForm.get('vastaanottaja')
  // }
  get aihe() {
    return this.lahetysForm.get('aihe')
  }
  get tekstinAlku() {
    return this.lahetysForm.get('tekstinAlku')
  }
  get tekstinLoppu() {
    return this.lahetysForm.get('tekstinLoppu')
  }
  get tuloslaskelma() {
    return this.lahetysForm.get('tuloslaskelma')
  }
  get tase() {
    return this.lahetysForm.get('tase')
  }
  get paakirja() {
    return this.lahetysForm.get('paakirja')
  }
  get alvIlmoitus() {
    return this.lahetysForm.get('alvIlmoitus')
  }
  get selvitettavatOstot() {
    return this.lahetysForm.get('selvitettavatOstot')
  }
  get selvitettavatMyynnit() {
    return this.lahetysForm.get('selvitettavatMyynnit')
  }
  get piilokopioLahettajalle() {
    return this.lahetysForm.get('piilokopioLahettajalle')
  }
  // lisaaVastaanottaja() {
  //   const field = this.vastaanottaja
  //   field.updateValueAndValidity()
  //   this.lisaaEmail(field)
  // }
  // poistaVastaanottaja(email: string): void {
  //   const index = this.vastaanottajat.findIndex(v => v.email === email)
  //   if (index >= 0) {
  //     this.vastaanottajat.splice(index, 1)
  //   }
  // }

  validateEmail: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    return Validators.email(ctrl)
  }

  async laheta() {

    // Avoid multiple sends
    if (this._sendingInProgress) {
      return
    }

    this._sendingInProgress = true

    if (!this.vastaanottajat?.length) {
      this._snackbar.open('Sähköpostia ei voi lähettää. Asiakkaalla ei ole aktiivisia yhteyshenkilöitä.', 'OK', { duration: 10000 })
      return
    }
    if (!this.lahetysForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.lahetysForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {
      // Obtain required user, etc. data
      const asiakas = await firstValueFrom(this._asiakasObservable)
      const selectedMonth = await firstValueFrom(this.valittuKuukausiObservable)
      const tallentavaKirjanpitaja = await this._kayttajaService.getKirjanpitajanTiedot()
      const latestAlvIlmoitus = await firstValueFrom(this.latestAlvIlmoitusObservable)
      const mailText = this.combineMailText()

      if (!asiakas) {
        throw new Error('Ei asiakasta tallennettaessa!')
      }

      if (!selectedMonth) {
        throw new Error('Ei valittua kuukautta tallennettaessa!')
      }

      if (!tallentavaKirjanpitaja) {
        throw new Error('Ei tallentavaa kirjanpitäjää tallennettaessa!')
      }

      const date = this._dateService.localDateToDate({ year: selectedMonth.year, month: selectedMonth.month, day: 1 })
      const alvKausi = this._asiakasJaettuService.annaNykyinenAlvIlmoitusjaksoPaivalleIlmanObjektia(asiakas, date)
      const noAlv = alvKausi === AlvIlmoitusjakso.EI || alvKausi === AlvIlmoitusjakso.TUNTEMATON
      if (!noAlv && this.alvIlmoitus.value && !latestAlvIlmoitus.endDate) {
        throw new Error('Asiakas asiakkaat/' + asiakas.avain + ':  No end date for alv-ilmoitus ' + JSON.stringify(latestAlvIlmoitus, null, '  '))
      }

      // Create saved documents
      const aika = this._timestampService.now()
      const lahetysAvain: string = this._lahetysAvainSubject.value
      const tyojonoId: string = this._firebaseLemonator.firestoreCreateId()
      const tyojonoData: KirjanpitoEmailLahetysTyojono = {
        asiakasAvain: asiakas.avain,
        kirjanpitoEmailLahetysAvain: lahetysAvain
      }
      const recipientStatuses: EmailLahetysStatus[] = []
      for (const recipient of this.vastaanottajat) {
        const emailLahetysStatus: EmailLahetysStatus = {
          email: recipient,
          status: EmailLahetysStatusKoodi.PROSESSOIDAAN,
          viesti: null
        }
        recipientStatuses.push(emailLahetysStatus)
      }
      const tekstiWithBreaks = mailText.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      const lahetysData: KirjanpitoEmailLahetys = {
        avain: lahetysAvain,
        kuukausi: selectedMonth,
        tunniste: selectedMonth.month + '_' + selectedMonth.year,
        luoja: tallentavaKirjanpitaja.uid,
        luotu: aika,
        lahetetty: null,
        lisaaPiilokopio: this.piilokopioLahettajalle.value,
        recipients: recipientStatuses,
        kieli: asiakas.laskunKieli || 'fi',
        aihe: this.aihe.value,
        teksti: tekstiWithBreaks,
        liitaTuloslaskelma: this.tuloslaskelma.value,
        liitaTase: this.tase.value,
        liitaPaakirja: this.paakirja.value,
        liitaAlvIlmoitus: this.alvIlmoitus.value,
        liitaSelvitettavatOstot: this.selvitettavatOstot.value,
        liitaSelvitettavatMyynnit: this.selvitettavatMyynnit.value,
        alvIlmoitus: noAlv ? null : (latestAlvIlmoitus || null)
      }

      // Emergency break to avoid empty email sends
      if (!lahetysData.aihe.replace(/\s/g, '') || !lahetysData.teksti.replace(/(?:<br\/>|\s)/g, '')) {
        this._ladataanService.lopetaLataaminen()
        this._sendingInProgress = false
        this._snackbar.open('Lähetys keskeytetty! Aihe tai teksti puuttuu', 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom' })
        return
      }

      const asiakasUri = 'asiakkaat/' + asiakas.avain
      const lahetysUri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksetLahetysAvaimella(asiakas.avain, lahetysAvain)
      const tyojonoUri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetysTyojonoEnsimmainenUri(asiakas.avain, tyojonoId)

      const lahetysTiedot: KirjanpitoraporttienLahetystiedot = {
        // talousraporttienVastaanottajat: this.talousraporttienVastaanottajat,
        liitaTuloslaskelma: lahetysData.liitaTuloslaskelma,
        liitaTase: lahetysData.liitaTase,
        liitaPaakirja: lahetysData.liitaPaakirja,
        liitaAlvIlmoitus: lahetysData.liitaAlvIlmoitus,
        liitaSelvitettavatOstot: lahetysData.liitaSelvitettavatOstot,
        liitaSelvitettavatMyynnit: lahetysData.liitaSelvitettavatMyynnit
      }


      // Update values going to the history version too
      asiakas.kirjanpitoraporttienLahetystiedot = lahetysTiedot
      asiakas.paivitetty = this._timestampService.now()
      asiakas.paivittaja = tallentavaKirjanpitaja.uid

      // Add to batch
      const batch = this._firebaseLemonator.firestoreBatch()
      this._asiakasService.lisaaAsiakkaanHistoriaBatchiin(asiakas, batch)
      const asiakasDocToUpdate: Partial<Asiakas> = {
        kirjanpitoraporttienLahetystiedot: lahetysTiedot,
        paivitetty: this._timestampService.now(),
        paivittaja: tallentavaKirjanpitaja.uid
      }
      batch.update(asiakasUri, asiakasDocToUpdate)
      batch.set(tyojonoUri, tyojonoData)
      batch.set(lahetysUri, lahetysData)

      // Save
      await batch.commit()

      this._ladataanService.lopetaLataaminen()
      this._snackbar.open('Sähköposti lähetettiin onnistuneesti.', 'OK', { duration: 5000 })
      await this._resetAutosave()
      this._lahetysAvainSubject.next(this._firebaseLemonator.firestoreCreateId())
    } catch (error) {
      this._errorHandler.handleError(error)
    } finally {
      this._ladataanService.lopetaLataaminen()
      this._sendingInProgress = false
    }
  }

  async downloadSentReport(lahetysAvain: string, reportType: RaporttiName) {
    const asiakas = await firstValueFrom(this._asiakasObservable)

    this._ladataanService.aloitaLataaminen()
    const downloadRequest: KirjanpitoEmailLahetysHistoryPdfRequest = {
      asiakasAvain: asiakas.avain,
      reportType: reportType,
      lahetysAvain: lahetysAvain
    }
    try {
      this._firebaseLemonator.functionsCall<KirjanpitoEmailLahetysHistoryPdfRequest, string>('kirjanpitoEmailLahetysDownloadPdf', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        this._ladataanService.lopetaLataaminen()
        const nimi = asiakas.nimi + '_' + reportType + '_' + lahetysAvain + '.pdf'
        this._fileSaverService.saveBase64As(data, nimi, 'pdf')
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }
  async downloadAttachment(liite: EmailLiite) {
    const asiakas = await firstValueFrom(this._asiakasObservable)

    this._ladataanService.aloitaLataaminen()
    const downloadRequest: EmailLiiteDownloadRequest = {
      asiakasAvain: asiakas.avain,
      liite: liite
    }
    try {
      this._firebaseLemonator.functionsCall<EmailLiiteDownloadRequest, string>('kirjanpitoDownloadEmailLiite', downloadRequest).then(data => {
        if (!data) {
          this._ladataanService.lopetaLataaminen()
          throw Error('Unexpected PDF download error - check console')
        }
        this._fileSaverService.saveBase64AsGuessedType(data, liite.nimi)
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

  // private lisaaEmail(field: AbstractControl) {
  //   if ((field.value || '').trim()) {
  //     let arvo = field.value.trim()
  //     arvo = arvo.replace(/,/g, '').trim()
  //     if (!new UntypedFormControl(arvo, [Validators.email]).errors) {
  //       if (!this.talousraporttienVastaanottajat) {
  //         this.talousraporttienVastaanottajat = []
  //       }
  //       this.talousraporttienVastaanottajat.push(arvo)
  //       field.setValue('')
  //     }
  //   }
  // }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  public downloadTulos() {
    this._downloadPdf(RaporttiType.TULOS)
  }
  public downloadTase() {
    this._downloadPdf(RaporttiType.TASE)
  }
  public downloadPaakirja() {
    this._downloadPdf(RaporttiType.PAAKIRJA)
  }
  public downloadAlvIlmoitus() {
    this._downloadPdf(RaporttiType.ALV_ILMOITUS)
  }
  public downloadSelvitettavatOstot() {
    this._downloadPdf(RaporttiType.SELVITETTAVAT_OSTOT)
  }
  public downloadSelvitettavatMyynnit() {
    this._downloadPdf(RaporttiType.SELVITETTAVAT_MYYNNIT)
  }

  private async _downloadPdf(type: RaporttiType) {

    this._ladataanService.aloitaLataaminen()

    const asiakas = await firstValueFrom(this._asiakasObservable)
    const valittuKuukausi = await firstValueFrom(this.valittuKuukausiObservable)

    if (!asiakas || !valittuKuukausi) {
      this._ladataanService.lopetaLataaminen()
      return
    }

    const latestAlvIlmoitusPromise = firstValueFrom(this.latestAlvIlmoitusObservable)

    const valitunKuukaudenAlku: LocalDate = { year: valittuKuukausi.year, month: valittuKuukausi.month, day: 1 }
    const valitunKuukaudenLoppu = this._dateService.kuukaudenViimeinenPaikallinen(valitunKuukaudenAlku)

    const pyynto: RaporttiRequest = {
      a: asiakas.avain,
      k: asiakas.laskunKieli || 'fi',
      w: type,
      s: this._dateService.localDateToNumber(valitunKuukaudenAlku),
      e: this._dateService.localDateToNumber(valitunKuukaudenLoppu)
    }

    if (type === RaporttiType.ALV_ILMOITUS) {
      pyynto.ai = await latestAlvIlmoitusPromise
    }

    try {
      return this._firebaseLemonator.functionsCall<RaporttiRequest, RaporttiPdfResponse>('kirjanpitoRaportitPdf', pyynto).then(data => {
        if (data.e) {
          this._ladataanService.lopetaLataaminen()
          switch (data.e) {
            case 'view-not-allowed': {
              this._snackbar.open('Käyttäjälla ei ole oikeutta nähdä tätä asiakasta.', 'OK')
              break
            }
            case 'ei-maksuyhteystietoja': {
              this._snackbar.open('Asiakkaalle ei ole lisätty veromaksuyhteystietoja', 'OK')
              break
            }
            case 'invalid-omaaloitteisetviite': {
              this._snackbar.open('Asiakkaan oma-aloitteisien verojen viitenumero on väärä tai puuttuu.', 'OK')
              break
            }
            default: {
              this._snackbar.open('Tietojen lataaminen epäonnistui.', 'OK')
            }
          }
        } else {
          this._ladataanService.lopetaLataaminen()
          if (data.base64File) {
            const fileName = data.fileName || 'raportti.pdf'
            this._fileSaverService.saveBase64As(data.base64File, fileName, 'pdf')
          } else {
            this._snackbar.open('PDF:in lataaminen epäonnistui.')
          }
        }
      })
    } catch (error) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(error)
    }
  }

  async deleteAttachment(file: EmailLiite) {

    this._ladataanService.aloitaLataaminen()
    const asiakas = await firstValueFrom(this._asiakasObservable)

    const uri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksenLiitteenUri(asiakas.avain, this._lahetysAvainSubject.value, file.avain)
    const markAsDeleted: Partial<EmailLiite> = { poistettu: true }
    await this._firebaseLemonator.firestoreUpdateData<Partial<EmailLiite>>(uri, markAsDeleted)
    this._ladataanService.lopetaLataaminen()

  }

  // Load start when clicked
  public async lataa(event: Event) {
    this.latausVirhe = null
    const inputTypeFile = event?.target as HTMLInputElement
    if (inputTypeFile?.files) {
      const list: FileList = inputTypeFile.files
      const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)
      try {
        await this._uploadFile(tiedostot)
      } catch (err) {
        this.latausVirhe = 'Lataamisen aikana tapahtui virhe: ' + err
        this._errorHandler.handleError(err)
      }
    }
  }

  private async _uploadFile(tiedostot: NgxFileDropEntry[]) {

    this._ladataanService.aloitaLataaminen()

    const asiakasPromise = firstValueFrom(this._asiakasObservable)
    const valittuKuukausiPromise = firstValueFrom(this.valittuKuukausiObservable)

    const [asiakas, valittuKuukausi] = await Promise.all([asiakasPromise, valittuKuukausiPromise])

    const batch = this._firebaseLemonator.firestoreBatch()

    for (const tiedosto of tiedostot) {

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(tiedosto)

      const file = await this._tiedostojenLataamisService.getFile(tiedosto.fileEntry as FileSystemFileEntry)

      const avain = this._firebaseLemonator.firestoreCreateId()
      const metadata: EmailLiite = {
        avain: avain,
        nimi: file.name,
        koko: file.size,
        ladattu: this._timestampService.now(),
        fileType: fileEnding,
        poistettu: false
      }

      const storageUri = this._kirjanpitoUriService.annaEmailLahetyksenLiiteStorageUri(asiakas.avain, metadata.avain, metadata.fileType)
      const uploadTask = this._tiedostojenLataamisService.upload(this._firebaseLemonator, storageUri, file)
      await firstValueFrom(uploadTask.doneObservable)

      const metadataUri = this._kirjanpitoUriService.annaKirjanpitoEmailLahetyksenLiitteenUri(asiakas.avain, this._lahetysAvainSubject.value, avain)
      batch.set<EmailLiite>(metadataUri, metadata)
    }

    const autosaveUri = this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakas.avain, valittuKuukausi)
    const data: Partial<KirjanpitoEmailTextAutosaveData> = {
      lahetysAvain: this._lahetysAvainSubject.value
    }
    batch.set<Partial<KirjanpitoEmailTextAutosaveData>>(autosaveUri, data, { merge: true })

    await batch.commit()

    this._ladataanService.lopetaLataaminen()
  }

  private _getTextFromAlvSum(alvSum: number): string {
    if (alvSum > 0) {
      return 'positive-alv'
    } else if (alvSum < 0) {
      return 'negative-alv'
    }
    return 'zero-alv'
  }
  private _createLiitteetListText(selectedLiitteet: string[], kieli: TuettuKieli, clientType: 'holvi' | 'regular'): string {
    if (!selectedLiitteet || !selectedLiitteet.length) {
      return ''
    }
    const commaSeparatedLiitteet = selectedLiitteet.join(', ')
    const finalComma = commaSeparatedLiitteet.lastIndexOf(', ')
    const liitteetList: string = selectedLiitteet.length > 1 ?
      `${commaSeparatedLiitteet.substring(0, finalComma)} ${kieli === 'en' ? 'and' : 'ja'}${commaSeparatedLiitteet.substring(finalComma + 1)}` : // add " ja", if more than one liite
      selectedLiitteet.toString()

    return this._translationService.lokalisoi('kirjanpito-lahetys.' + clientType + '.liitteet-text', kieli, { liitteet: liitteetList })
  }

  private _getSelvitettavatText(asiakas: Pick<Asiakas, 'kasittelija' | 'laskunKieli' | 'yritysmuoto'>, ostotChecked: boolean, myynnitChecked: boolean): string {
    const clientType: 'holvi' | 'regular' = this._kirjanpitoJaettuService.getClientTypeAsString(asiakas)

    if (clientType === 'holvi') {
      return this._getHolviSelvitettavat(asiakas, ostotChecked, myynnitChecked)
    }
    return this._getRegularSelvitettavat(asiakas, ostotChecked, myynnitChecked)
  }

  private _getHolviSelvitettavat(asiakas: Pick<Asiakas, 'laskunKieli' | 'yritysmuoto'>, ostotChecked: boolean, myynnitChecked: boolean): string {
    const kieli = asiakas.laskunKieli || 'fi'
    if (!ostotChecked && !myynnitChecked) {
      return this._translationService.lokalisoi('kirjanpito-lahetys.holvi.ei-selvitettavia', kieli)
    }
    const selvitettaviaOstojaText = ostotChecked ? this._translationService.lokalisoi('kirjanpito-lahetys.holvi.selvitettavat-ostot', kieli) : ''
    const onkoSelvitettaviaMyyntejaText = myynnitChecked ? this._translationService.lokalisoi('kirjanpito-lahetys.holvi.selvitettavat-myynnit', kieli) : ''
    return selvitettaviaOstojaText + onkoSelvitettaviaMyyntejaText
  }

  private _getRegularSelvitettavat(asiakas: Pick<Asiakas, 'laskunKieli' | 'yritysmuoto'>, ostotChecked: boolean, myynnitChecked: boolean): string {
    const kieli = asiakas.laskunKieli || 'fi'
    if (!ostotChecked && !myynnitChecked) {
      return this._translationService.lokalisoi('kirjanpito-lahetys.regular.ei-selvitettavia', kieli)
    }
    if (ostotChecked && myynnitChecked) {
      if (asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI || asiakas.yritysmuoto === Yritysmuoto.AVOINYHTIO || asiakas.yritysmuoto === Yritysmuoto.KOMMANDIITTIYHTIO) {
        return this._translationService.lokalisoi('kirjanpito-lahetys.regular.selvitettavat-ostot-ja-myynnit-tmi', kieli)
      }
      return this._translationService.lokalisoi('kirjanpito-lahetys.regular.selvitettavat-ostot-ja-myynnit-oy', kieli)
    }

    if (ostotChecked) {
      if (asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI || asiakas.yritysmuoto === Yritysmuoto.AVOINYHTIO || asiakas.yritysmuoto === Yritysmuoto.KOMMANDIITTIYHTIO) {
        return this._translationService.lokalisoi('kirjanpito-lahetys.regular.selvitettavat-ostot-tmi', kieli)
      }
      return this._translationService.lokalisoi('kirjanpito-lahetys.regular.selvitettavat-ostot-oy', kieli)
    }

    if (myynnitChecked) {
      return this._translationService.lokalisoi('kirjanpito-lahetys.regular.selvitettavat-myynnit', kieli)
    }

    return ''
  }

  async tryAutosave() {

    const asiakasPromise = firstValueFrom(this._asiakasObservable)
    const kuukausiPromise = firstValueFrom(this.valittuKuukausiObservable)
    const kirjanpitajanTiedotPromise = this._kayttajaService.getKirjanpitajanTiedot()

    const aihe = this.aihe.value || ''
    const alku = this.tekstinAlku.value || ''
    const loppu = this.tekstinLoppu.value || ''

    if (!aihe && !alku && !loppu) {
      return
    }

    const [asiakas, kirjanpitajanTiedot, kuukausi] = await Promise.all([asiakasPromise, kirjanpitajanTiedotPromise, kuukausiPromise])
    if (!asiakas || !kirjanpitajanTiedot || !kuukausi) {
      return
    }

    const kieli = asiakas.laskunKieli || 'fi'
    const autosaveUri = this._kirjanpitoUriService.annaKirjanpitoEmailTekstiAutosaveUri(asiakas.avain, kuukausi)
    const aiheet: Partial<{ [k in TuettuKieli]: string }> = {}
    const alut: Partial<{ [k in TuettuKieli]: string }> = {}
    const loput: Partial<{ [k in TuettuKieli]: string }> = {}
    aiheet[kieli] = aihe
    alut[kieli] = alku
    loput[kieli] = loppu
    const data: Partial<KirjanpitoEmailTextAutosaveData> = {
      aiheet: aiheet,
      tekstinAlut: alut,
      tekstinLoput: loput,
      paivittaja: kirjanpitajanTiedot.uid,
      paivitettu: this._timestampService.now()
    }

    return this._firebaseLemonator.firestoreSetData(autosaveUri, data, { merge: true })

  }

  public showOrHideMainAutotext() {
    this.includeMainAutotext = !this.includeMainAutotext
  }
  public showOrHideSelvitettavatAutotext() {
    this.includeSelvitettavatAutotext = !this.includeSelvitettavatAutotext
  }

  private combineMailText(): string {
    let output = ''
    output += this.tekstinAlku?.value || ''
    if (this.includeMainAutotext) {
      output = output.trimEnd()
      output += '\n\n'
      output += this.mainAutotext || ''
    }
    if (this.includeSelvitettavatAutotext) {
      output = output.trimEnd()
      output += '\n\n'
      output += this.selvitettavatAutotext || ''
    }

    output = output.trimEnd()
    output += '\n\n'
    output += (this.tekstinLoppu?.value as string || '').trimStart()
    return output
  }

  private _doesAutosaveHaveCheckboxData(autosaved: KirjanpitoEmailTextAutosaveData): boolean {
    return autosaved?.paakirjaValittu === true || autosaved?.paakirjaValittu === false ||
      autosaved?.taseValittu === true || autosaved?.taseValittu === false ||
      autosaved?.tulosValittu === true || autosaved?.tulosValittu === false ||
      autosaved?.alvValittu === true || autosaved?.alvValittu === false ||
      autosaved?.selvOstotValittu === true || autosaved?.selvOstotValittu === false ||
      autosaved?.selvMyynnitValittu === true || autosaved?.selvMyynnitValittu === false
  }

  private _setCheckboxesFromAutosave(autosaved: Partial<KirjanpitoEmailTextAutosaveData>): void {

    if (!autosaved) {
      return
    }

    if (autosaved.piilokopioValittu !== undefined && autosaved.piilokopioValittu !== null) {
      this.piilokopioLahettajalle.setValue(autosaved.piilokopioValittu)
    }
    if (autosaved.paakirjaValittu !== undefined && autosaved.paakirjaValittu !== null) {
      this.paakirja.setValue(autosaved.paakirjaValittu)
    }
    if (autosaved.taseValittu !== undefined && autosaved.taseValittu !== null) {
      this.tase.setValue(autosaved.taseValittu)
    }
    if (autosaved.tulosValittu !== undefined && autosaved.tulosValittu !== null) {
      this.tuloslaskelma.setValue(autosaved.tulosValittu)
    }
    if (
      this.alvIlmoitus.enabled &&
      autosaved.alvValittu !== undefined &&
      autosaved.alvValittu !== null
    ) {
      this.alvIlmoitus.setValue(autosaved.alvValittu)
    }
    if (
      this.selvitettavatOstot.enabled &&
      autosaved.selvOstotValittu !== undefined &&
      autosaved.selvOstotValittu !== null
    ) {
      this.selvitettavatOstot.setValue(autosaved.selvOstotValittu)
    }
    if (
      this.selvitettavatMyynnit.enabled &&
      autosaved.selvMyynnitValittu !== undefined &&
      autosaved.selvMyynnitValittu !== null
    ) {
      this.selvitettavatMyynnit.setValue(autosaved.selvMyynnitValittu)
    }
  }

}
