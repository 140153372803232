import { ErrorResponse, LocalDate, NumberDate, Timestamp } from '../../_shared-core/model/common'
import { Bic } from '../../_shared-core/service/bic.service'

export interface Pankki {
  avain: Bic
  nimi: string
}

export interface Tili {
  avain: string
  pankinAvain: string
  iban: string
  bic: string
}

export interface TilitapahtumaOLD {
  avain: string
  /**
   * vvvvkkppxxxx
   */
  jarjestys: number
  pankinAvain: string
  iban: string
  bic: string
  viite: string
  yliajoviite: string
  summa: string
  valuutta: string
  viesti: string
  arvopvm: Timestamp
  arvopvml: LocalDate
  kirjauspvm: Timestamp
  kirjauspvml: LocalDate
  /**
   * ISO 20022 Bank Transaction Code
   * https://www.iso20022.org/external_code_list.page
   */
  isoBtcCode: string
  lahde: 'tink' | 'kasin' | 'camt.053' | 'fivaldi_tito'
  creditDebitCode: 'CRDT' | 'DBIT'
  lisatiedot: string

  maksaja: string

  kohdistukset: TilitapahtumanKohdistusLaskuun[]
  kohdistamatta: number
  kohdistettu: number

  pankinXml: any
}

export interface TilitapahtumanLisatiedot {

  /**
   * Raakadata, jonka olemme saaneet, xml / html / json
   */
  raakadata: any

  lahde: 'tink' | 'kasin' | 'camt.053' | 'fivaldi_tito' | 'viia' | 'holvi' | 'holviapi'

  // Reskontra
  kohdistukset?: TilitapahtumanKohdistusLaskuun[]

}

export interface Tilitapahtuma {

  avain: string

  /** Key to identify the Pankki that this transaction is part of */
  pankinAvain: string

  /** Iban kuten ISO 13616 määrittää */
  iban: string

  /** The BIC code like NDEAFIHH */
  bic: Bic

  /**
   * vvvvkkppxxxxx
   */
  jarjestys: number

  /**
   * The SEPA archival id
   */
  archivalId: string

  /**
   * The id that the bank has produced for this Tilitapahtuma. From PSD2 interface.
   */
  bankId: string

  /**
   * The id that the integration gateway (Like Viia) has assigned.
   */
  dataProviderId: string

  summa: number
  saldo: number

  /** The three letter currency identifier such as EUR according to ISO 4217 */
  valuutta: string

  pvm: Timestamp
  pvml: LocalDate
  p: NumberDate

  lisatiedot: string
  maksaja: string
  maksajanIban: string
  viesti: string
  viite: string

  poistettu: boolean
  tila: 'final' | 'unconfirmed'

  yliajoviite?: string
  kohdistamatta?: number
  kohdistettu?: number

  tagTositteet?: string[]
  tagRaahatut?: string[]
  tagLaskut?: string[]

}

export interface TilitapahtumanKohdistusLaskuun {
  avain: string
  laskuAvain: string
  laskunro: string
  laskunsaaja: string
  suoritus: number
  luotu: Timestamp
  vastaparinAvain: string
}














export interface LataaCamt053DokuPyynto {
  asiakasId: string
  asiakasAvain: string
  base64File: string
}

export interface LataaCamt053DokuVastaus {
  onnistui: boolean
  tallennettiinPankkeja: number
  tallennettiinTileja: number
  tallennettiinTapahtumia
}

export interface TilitapahtumanTuontityodata {
  tilitapahtuma: Tilitapahtuma
  lisatty: Timestamp
  lisatiedot: TilitapahtumanLisatiedot
  kohdistaUudelleen?: true
  yliajoviite?: string
}

export interface TilitapahtumanTuontityodataJaAsiakasTiedot extends TilitapahtumanTuontityodata {
  asiakasAvain: string
  asiakasId: number
}

// eslint-disable-next-line no-shadow
export enum BankBalanceCalcAlgorithm {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AIIA_OLD = 'aiia-old',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  VERSION_ONE = 'v1',
}

export interface BankBalance {
  avain: string
  iban: string
  saldo: number
  paiva: NumberDate
  source: 'aiia' | 'tiliote' | 'holvi'
  currency: string
  edited: Timestamp
  algoVersion: BankBalanceCalcAlgorithm
}


export interface BankBalanceHistory extends BankBalance { }

export interface BankBalancesPerMonth {
  /**
  * Format yymm
  */
  kuukausi: number
  asiakasAvain: string
  iban: string
  /**
  * Key is 'p' + day number
  */
  saldot: { [day: string]: BankBalance }
  algoVersion: BankBalanceCalcAlgorithm
}

export interface BankBalancesPerMonthHistory extends BankBalancesPerMonth {
  updatedAt: Timestamp
}


export interface BankBalanceAddManuallyRequest {
  asiakasAvain: string
  iban: string
  date: NumberDate
  balance: number
  source: 'tiliote'
}

export interface BankBalanceAddManuallyResponse extends ErrorResponse<'missing-data' | 'unknown-error'> { }


// eslint-disable-next-line no-shadow
export enum SahkoisenTiliotteenAlatyyppi {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HOLVI_API = 'holvi',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AIIA = 'aiia',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NORDIGEN = 'nordigen',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WEB_SERVICES = 'ws'
}

export interface LatestBankBalance {
  balance: number
  fetchTime: Timestamp
}




