import { Component, OnInit, OnDestroy, ErrorHandler, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core'
import { Validators, AbstractControl, ValidatorFn, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'

import { Asiakas, AsiakkaanMaksutapa, Kayttaja, LemonatorPaymentConfig } from '../_jaettu-lemonator/model/asiakas'
import { MAKSUTAPA_MYYNTILASKU, MAKSUTAPA_PALKKATOSITE, MAKSUTAPA_MYYNTITOSITE, MAKSUTAPA_TILIOTETOSITE, MAKSUTAPA_MUU, MAKSUTAPA_SAHKOISET_LASKUT, MAKSUTAPA_SAHKOISET_LASKUT_FAKE } from '../_jaettu/model/kayttaja'
import { KirjauksienLuontityyppi, Kirjanpitotili } from '../_jaettu-lemonator/model/kirjanpito'
import { SahkoisenTiliotteenAlatyyppi } from '../_jaettu/model/tiliote'
import { BankConnection, BankConnectionDisableRequest, BankConnectionDisableResponse } from '../_jaettu/model/bank-connection'

import { AsiakasService } from '../_angular/service/asiakas/asiakas.service'
import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { TilikarttaService } from '../_angular/service/tilikartta.service'
import { BicService } from '../_shared-core/service/bic.service'
import { StringService } from '../_shared-core/service/string.service'
import { IbanService } from '../_shared-core/service/iban.service'
import { DateService } from '../_shared-core/service/date.service'


import { Subject, combineLatest, BehaviorSubject, Observable, of as observableOf, firstValueFrom, of } from 'rxjs'
import { map, takeUntil, debounceTime, startWith, tap, take, switchMap, withLatestFrom } from 'rxjs/operators'
import { FirebaseLemonaid, FirebaseLemonator } from 'app/_angular/service/firebase-lemonator.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { KirjautunutKayttajaService } from 'app/_angular/service/kirjautunut-kayttaja.service'
import { DebugService } from 'app/_angular/service/debug.service'
import { MatDialog } from '@angular/material/dialog'
import { AsiakkaanMaksutavatValitseKuvakeDialog } from './asiakkaan-maksutavat.valitse-kuvake.dialog'
import { TilikarttaJaettuService } from 'app/_jaettu-lemonator/service/tilikartta-jaettu.service'
import { MatInput } from '@angular/material/input'
import { AsiakasKopioija } from 'app/_angular/service/asiakas/asiakas.kopioija'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'

interface LokalisoituTiliotetyyppi {
  nimi: string
  tunniste: KirjauksienLuontityyppi
  alitunniste: SahkoisenTiliotteenAlatyyppi
}

interface MaksutapaJaKayttajat {
  kayttajat: Kayttaja[]
  maksutapa: AsiakkaanMaksutapa
  uusi: boolean
  onkoValtuutus: boolean
}

interface PankkiJaIban extends Pick<BankConnection, 'iban' | 'disabledInLemonator' | 'source'> {
  pankki: string
  bic: string
}

interface MaksutavatForm {
  maksutavat: FormArray<FormGroup<MaksutapaFormGroup>>
}


interface MaksutapaFormGroup {
  nimi: FormControl<string>
  kirjaustenLuontityyppi: FormControl<LokalisoituTiliotetyyppi>
  sahkoisenTiliotteenTunniste: FormControl<string>
  oletusVastatili: FormControl<string>
  aktiivinen: FormControl<boolean>
  tilitapahtumatSynkronoidaanAlkaen: FormControl<Date>
  tilitapahtumatSynkronoidaanLoppuen: FormControl<Date>
  omallaRahallaUid: FormControl<AsiakkaanMaksutapa['omallaRahallaUid']>
}

interface MaksuConfigsForm {
  hyvaksyjienLukumaara: FormControl<number>
  configs: FormArray<FormGroup<MaksuConfigFormGroup>>
}

interface MaksuConfigFormGroup {
  start: FormControl<Date>
  end: FormControl<Date>
  iban: FormControl<string>
  debtorIdentifier: FormControl<string>
}

@Component({
  templateUrl: './asiakkaan-maksutavat.component.html',
  styleUrls: ['./asiakkaan-maksutavat.component.css']
})
export class AsiakkaanMaksutavatComponent implements OnInit, OnDestroy {

  @ViewChildren('debtorIdentifier', { read: MatInput }) debtorIdentifierit: QueryList<MatInput>

  // @ViewChild(MatInput) etunimiInput: MatInput
  // @ViewChild('fileInput', { static: true }) fileInput: ElementRef

  // eslint-disable-next-line max-len
  private _defaultMaksutapaKuva = 'iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAHlBMVEX09PTh4eH19fXg4ODk5OTw8PDs7Ozq6uru7u7n5+dZKxXMAAAELUlEQVR4nO2dWXKtMAwFwQMX9r/hB9RNMOARhCTyTv/kIxVQl4kHYZmuAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgAsZ03o9eA6OfwyH360bXWz30kyd2HHvbq8LaibQdJ2V+C7Yf6RSdQsEZOxApGqWCsyJNK5qPVsEZCsHOS1tksBNFI2rsZTY8gaG0QxaCRjRj0ITS4/wvW0judguaKbjaID1f+xLEZG8/psZtV6OeKF3GbH2DHW9fbDN0WgTn/j0wvBtVYEjSM9MAwxZgKAMMW4ChDLyGxoQ/eGA0NMYPk3O9c9PH80nyGZo1A/e9l3WEiZM8DxmeZm3GH/Ib1jFNXZna0AzntTFZcigPj2E8f2M/HIoshqkEFYsih+Fu7b9XZOhvWNow4bdw85YVMBjmkqg0Kb4sHG2YaUKaFF+W5w3zefDnO5vnR/wwPxXh8YwOw1Oaz4Pbmzct8ryhLxhe+EdsivRxw/RgePW2821a/uZ5w8ILt/auZp7Ct8Qqb9g6/17XKA3BKnhK227yswirjvZtPc22yqwN912jxW6bQGW87xrx93mCuoBfNWs7JkKqIuaYeWcf04brn3ey1ITMsXrK7F9oWD1Ft+pUxPyeFXB8L1I5aJYsRnKbTcM9E5utyleQzUTVz2eSu8mKYb8km5jbLleI+xUZ4cJ+wHzgr8jqFzY85iNnfPe0vJn5uVff8mamuKMzGzrn27Xu9+3a4Bu2JddsWc3Erv4NaeWe3HTw6t9yV246Tkev3bB6V3UyfOWGDdvGU/HrNmzbFx8X0GzYvPE/asA14l+hubIhqqC4DS+UbsQc9Bpeq005S2g1vFx8c7LQani5uuikIWhohnR5wI3yqaOHnOG8ZLQpxVv1YYd1p5jhuiZOKN4sgNsrShl+F/0xxfsVfjtFoRE/yGqcfkdQwhgqyrRhmLY5tiJJjWbwvkfEcJeXOjyoNEWoVrYND4m3UJGqyla2DU+ZxUCRqoxY1DCWOv3pbsjqpCUNo4LfVqQrBBc0jAquioaylF/OMCG4KlKW8j9vmBjxk4LUSLUhm6CUIZ+gkCGjoIwhp6CIIaughCGvoIAhsyC/Ibcg+4jPLsjdhvyCzIYCgryGEoK8hoV93u83lDn+i9VQ4iGFIQwrDN2fN/z7bQhDGMIQhjCEYdnwvxrxPyKnljIadn6QgNOwMxKwGgoDwxZgKAMMW4ChDE/N2kiCoyA4eYTgPO/t/PPluyc6COrICc5kDybZ1jodhPP+u4LFg1qkofjXyR9jIgzFaaKl84Rkoen9FDci1Tmb0h5JqM6gNFo7m2R5zgVFbd8kW5nHZzJOx0MowNqhHHiL4+h2H7ARZvl4HvUHAtfD1yfpycyX6TPSfwCxE8o+JXhADwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAs/ANyGFT0fw3sTAAAAABJRU5ErkJggg=='

  private _lisaaKuvaIndeksiin: number = 0
  private _sallitutKirjanpitotilinumerot: Set<string>
  private _ngUnsubscribe: Subject<void> = new Subject<void>()
  private _kaikkiKirjanpitotilitObservable: Observable<Kirjanpitotili[]>

  maksutavatUriObservable: Observable<string>
  maksutavatEncodedUriObservable: Observable<string>
  maksutavatLemonaidCustomersUriObservable: Observable<string>
  maksutavatLemonaidCustomersEncodedUriObservable: Observable<string>
  maksutavatLemonaidRealtimeDbUriObservable: Observable<{ kayttaja: string, uri: string, encodedUri: string }[]>
  bankConnectionsUriObservable: Observable<string>
  bankConnectionsEncodedUriObservable: Observable<string>
  kirjanpitajaOnDevaajaObservable: Observable<boolean>
  naytaMaksutilitObservable: Observable<boolean>
  asiakkaanApixTiedotObservable: Observable<Partial<ApixReceivedInvoiceConfig>>
  asiakkaanOstolaskujenMaksajaRoolinKayttajatObservable: Observable<Kayttaja[]>
  asiakkaanOstolaskujenHyvaksyjaRoolinKayttajatObservable: Observable<Kayttaja[]>

  asiakkaanIbanitJaPankitObservable: Observable<PankkiJaIban[]>

  suodatetutKirjanpitotilitSubject: BehaviorSubject<Kirjanpitotili[]> = new BehaviorSubject([])
  suodatetutKirjanpitotilitObservable: Observable<Kirjanpitotili[]> = this.suodatetutKirjanpitotilitSubject.asObservable()

  private _maksutavatChangedSubject: Subject<void> = new Subject()
  isHolviUlkopTositteetVisible: boolean = false
  isOmallaRahallaColumnVisible: boolean = false
  isHolviApiSelectionDisabled: boolean = false

  commonError: string
  commonErrorPaymentConfigs: string
  saveStarted: boolean
  lataaIbaneja: boolean = true
  lataaMaksutapoja: boolean = true
  inputname = 'asdfewqrphoki' + Math.random()
  form: FormGroup<MaksutavatForm>
  maksuConfigForm: FormGroup<MaksuConfigsForm>
  asiakas: Asiakas
  maksutavatJaKayttajat: MaksutapaJaKayttajat[]
  kaikkiKayttajatObservable: Observable<Kayttaja[]>
  kirjaustenLuontityypit: LokalisoituTiliotetyyppi[] = [
    { nimi: 'Sähköinen tiliote (Aiia)', tunniste: KirjauksienLuontityyppi.SAHKOINEN_TILIOTE, alitunniste: SahkoisenTiliotteenAlatyyppi.AIIA },
    /** TODO: Uncomment once connections are ready */
    { nimi: 'Sähköinen tiliote (Holvi API)', tunniste: KirjauksienLuontityyppi.SAHKOINEN_TILIOTE, alitunniste: SahkoisenTiliotteenAlatyyppi.HOLVI_API },
    // { nimi: 'Sähköinen tiliote (Holvi ZIP)', tunniste: KirjauksienLuontityyppi.SAHKOINEN_TILIOTE, alitunniste: SahkoisenTiliotteenAlatyyppi.HOLVI_ZIP },
    // { nimi: 'Sähköinen tiliote (Nordigen)', tunniste: KirjauksienLuontityyppi.SAHKOINEN_TILIOTE, alitunniste: SahkoisenTiliotteenAlatyyppi.NORDIGEN },
    // { nimi: 'Sähköinen tiliote (Web Services)', tunniste: KirjauksienLuontityyppi.SAHKOINEN_TILIOTE, alitunniste: SahkoisenTiliotteenAlatyyppi.WEB_SERVICES },

    // { nimi: 'Sähköinen luottokortti', tunniste: KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE }, /** NOT IN USE */
    { nimi: 'Luodaan tositteista', tunniste: KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE, alitunniste: null },
    { nimi: 'Manuaalinen kirjaus', tunniste: KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE, alitunniste: null },
    { nimi: 'Holvi ZIP', tunniste: KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE, alitunniste: null }
  ]
  kirjaustenLuontityypitByTunnisteetMap: Map<string, LokalisoituTiliotetyyppi> = new Map<string, LokalisoituTiliotetyyppi>(this.kirjaustenLuontityypit.map(tyyppi => {
    return [tyyppi.tunniste + '_' + tyyppi.alitunniste, tyyppi]
  }))

  holviOmallaRahallaTili: number = null

  constructor(
    private _errorHandler: ErrorHandler,
    private _changeDetectorRef: ChangeDetectorRef,
    private _asiakasService: AsiakasService,
    private _asiakasKopioija: AsiakasKopioija,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _tilikarttaService: TilikarttaService,
    private _snackBar: MatSnackBar,
    private _bicService: BicService,
    private _stringService: StringService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _firebaseLemonator: FirebaseLemonator,
    private _ibanService: IbanService,
    private _dateService: DateService,
    private _kirjautunutKayttajaService: KirjautunutKayttajaService,
    private _debugService: DebugService,
    private _dialog: MatDialog,
    private _tilikarttaJaettuService: TilikarttaJaettuService
  ) { }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  ngOnInit() {

    this.asiakkaanApixTiedotObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(avainTiedot => {
        if (avainTiedot?.avain) {
          const uri = 'customers/' + avainTiedot.avain + '/apix-received-invoice-config/' + avainTiedot.avain
          return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>(uri).listen().pipe(map(data => data || {}))
        }
        return of<Partial<ApixReceivedInvoiceConfig>>({})
      })
    )

    this._maksutavatChangedSubject.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(() => {

      const holviApiMaksutavatExist = this.maksutavatJaKayttajat.filter(mt => mt.maksutapa.sahkoisenTiliotteenAlatyyppi === SahkoisenTiliotteenAlatyyppi.HOLVI_API).length > 0
      this.isHolviUlkopTositteetVisible = holviApiMaksutavatExist

      const luodaanTositteistaMaksutavatExist = this.maksutavatJaKayttajat.filter(mt => mt.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE).length > 0
      this.isOmallaRahallaColumnVisible = luodaanTositteistaMaksutavatExist
    })

    // Debug thingies
    this.kirjanpitajaOnDevaajaObservable = this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable

    this.naytaMaksutilitObservable = combineLatest([
      this._kirjautunutKayttajaService.kirjanpitajaOnDevaajaObservable,
      this._kirjautunutKayttajaService.kirjanpitajaOnLuottoObservable
    ]).pipe(
      map(([devaaja, luotto]) => devaaja || luotto)
    )

    this.maksutavatUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'asiakkaat/' + avainTiedot.avain + '/maksutavat'
        }
        return ''
      })
    )
    this.maksutavatEncodedUriObservable = this.maksutavatUriObservable.pipe(
      map(uri => this._debugService.createFirestoreLink(uri))
    )
    this.maksutavatLemonaidCustomersUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'customers/' + avainTiedot.avain
        }
        return ''
      })
    )
    this.maksutavatLemonaidCustomersEncodedUriObservable = this.maksutavatLemonaidCustomersUriObservable.pipe(
      map(uri => this._debugService.createFirestoreLinkLemonaid(uri))
    )

    this.kaikkiKayttajatObservable = this._asiakasService.nykyisenAsiakkaanKayttajatObservable

    this.maksutavatLemonaidRealtimeDbUriObservable = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => {
        if (kayttajat) {
          return kayttajat.map(kayttaja => {
            return {
              kayttaja: kayttaja.etunimi + ' ' + kayttaja.sukunimi,
              uri: 'p/' + kayttaja.avain,
              encodedUri: this._debugService.createRealtimeDatabaseLinkLemonaidUsa('p/' + kayttaja.avain)
            }
          })
        }
        return []
      })
    )

    this.bankConnectionsUriObservable = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      map(avainTiedot => {
        if (avainTiedot?.avain) {
          return 'customers/' + avainTiedot.avain + '/bank-connections/'
        }
        return ''
      })
    )
    this.bankConnectionsEncodedUriObservable = this.bankConnectionsUriObservable.pipe(
      map(uri => this._debugService.createFirestoreLinkLemonaid(uri))
    )

    // Create form
    this.form = new FormGroup<MaksutavatForm>({
      maksutavat: new FormArray<FormGroup<MaksutapaFormGroup>>([])
    })

    const ostolaskujenHyvaksyntienLukumaaraControl = new FormControl<number>(null, [Validators.required, Validators.min(1)])
    this.maksuConfigForm = new FormGroup<MaksuConfigsForm>({
      'hyvaksyjienLukumaara': ostolaskujenHyvaksyntienLukumaaraControl,
      'configs': new FormArray<FormGroup<MaksuConfigFormGroup>>([], [])
    })

    ostolaskujenHyvaksyntienLukumaaraControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      if (this.asiakas) { this.asiakas.paymentsNumberOfApprovalsRequired = val }
    })

    this.asiakkaanOstolaskujenMaksajaRoolinKayttajatObservable = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => kayttajat?.filter(kayttaja => kayttaja.aktiivinen && kayttaja.roolit.MAKSUT_MAKSAJA) ?? [])
    )

    this.asiakkaanOstolaskujenHyvaksyjaRoolinKayttajatObservable = this._asiakasService.nykyisenAsiakkaanKayttajatObservable.pipe(
      map(kayttajat => kayttajat?.filter(kayttaja => kayttaja.aktiivinen && kayttaja.roolit.MAKSUT_HYVAKSYJA) ?? [])
    )

    this.asiakkaanOstolaskujenHyvaksyjaRoolinKayttajatObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(hyvaksyjat => {
      if (hyvaksyjat?.length > 0) {
        ostolaskujenHyvaksyntienLukumaaraControl.setValidators([Validators.required, Validators.min(1)])
      } else {
        ostolaskujenHyvaksyntienLukumaaraControl.setValidators([Validators.min(1)])
      }
    })

    this._kaikkiKirjanpitotilitObservable = this._tilikarttaService.nykyisenAsiakkaanTilikartanJaPaatilikartanTilitObservable.pipe(
      map(tilit => {
        return tilit.filter(tili => { return tili.numero.length > 3 })
      }),
      tap(tilit => {
        this._sallitutKirjanpitotilinumerot = new Set(tilit.map(tili => tili.numero))
      }),
      lemonShare()
    )

    const bankConnectionsObservable: Observable<BankConnection[]> = this._asiakasService.nykyinenAsiakasAvainObservable.pipe(
      switchMap(avainTiedot => {
        if (!avainTiedot || !avainTiedot.avain) {
          return observableOf<BankConnection[]>([])
        }
        this.lataaIbaneja = true

        const collection = 'customers/' + avainTiedot.avain + '/bank-connections/'
        return this._firebaseLemonaid.firestoreCollection<BankConnection>(collection).listen()
      }),
      lemonShare()
    )

    this.asiakkaanIbanitJaPankitObservable = bankConnectionsObservable.pipe(
      map((bankConnections) => {

        const output: PankkiJaIban[] = []

        for (const bankConn of bankConnections) {
          if (bankConn.isActive) {
            output.push({
              pankki: this._bicService.annaPankinNimi(bankConn.iban) || 'Tuntematon',
              iban: bankConn.iban,
              bic: this._bicService.annaBicKoodi(bankConn.iban) || 'Tuntematon',
              source: bankConn.source,
              disabledInLemonator: bankConn.disabledInLemonator
            })
          }
        }

        return output
      }),
      tap(() => this.lataaIbaneja = false)
    )

    this._asiakasService.nykyinenAsiakasObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(asiakas => {
      this.asiakas = this._asiakasKopioija.kopioiAsiakas(asiakas)
      this._alustaMaksuConfigLomakkeenTiedot(this.asiakas)
    })

    combineLatest([
      this._asiakasService.nykyisenAsiakkaanMaksutavatObservable, // .pipe(tap(() => console.log('Maksutavat changed'))),
      this._asiakasService.nykyisenAsiakkaanKayttajatObservable, // .pipe(tap(() => console.log('Käyttäjät changed'))),
      this._kaikkiKirjanpitotilitObservable // .pipe(tap(() => console.log('Tilit changed')))
    ]).pipe(
      withLatestFrom(this._kirjautunutKayttajaService.kirjanpitajaOnItTiiminJasenObservable),
      tap(() => {
        this.lataaMaksutapoja = true
      }),
      map(([[maksutavat, kayttajat, kirjanpitotilit], onkoItTiiminJasen]) => {

        const tulokset: MaksutapaJaKayttajat[] = []
        if (maksutavat) {
          for (const maksutapa of maksutavat) {

            const maksutapaJaKayttajat: MaksutapaJaKayttajat = {
              kayttajat: [],
              maksutapa: maksutapa,
              uusi: false,
              onkoValtuutus: null
            }

            if (maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE && !onkoItTiiminJasen) {
              this.isHolviApiSelectionDisabled = true
            }

            if (
              maksutapa.sahkoisenTiliotteenTunniste &&
              (
                maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE ||
                maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE
              )
            ) {
              maksutapaJaKayttajat.onkoValtuutus = false
            }

            if (kayttajat) {
              for (const kayttaja of kayttajat) {
                if (kayttaja.maksutavat) {
                  for (const m of kayttaja.maksutavat) {
                    if (m === maksutapa.tunniste) {
                      maksutapaJaKayttajat.kayttajat.push(kayttaja)
                      break
                    }
                  }
                }
              }
            }

            maksutapaJaKayttajat.kayttajat.sort((a, b) => {
              if (a.sukunimi !== b.sukunimi) {
                return a.sukunimi.localeCompare(b.sukunimi)
              }
              return a.etunimi.localeCompare(b.etunimi)
            })

            tulokset.push(maksutapaJaKayttajat)
          }
        }
        tulokset.sort((a, b) => {
          return a.maksutapa.tunniste - b.maksutapa.tunniste
        })
        return { maksutavat: tulokset, kirjanpitotilit: kirjanpitotilit }
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(result => {
      this.maksutavatJaKayttajat = result.maksutavat
      this._lisaaMaksutavatJaOletus(result.maksutavat, result.kirjanpitotilit)
      this.lataaMaksutapoja = false
      this._maksutavatChangedSubject.next()
    })

  }

  get maksutavatArray(): FormArray<FormGroup<MaksutapaFormGroup>> {
    return this.form.get('maksutavat') as FormArray<FormGroup<MaksutapaFormGroup>>
  }

  get maksutapaControllit(): AbstractControl[] {
    return this.maksutavatArray.controls
  }

  poistaMaksutapa(index: number) {

    this.maksutavatArray.removeAt(index)
    this.maksutavatJaKayttajat.splice(index, 1)

    // Järjestä tunnisteet uudelleen uusien osalta
    const vanhat = this.maksutavatJaKayttajat.filter(a => { return !a.uusi })
    const uudet = this.maksutavatJaKayttajat.filter(a => { return a.uusi })
    for (const uusiMaksutapa of uudet) {
      uusiMaksutapa.maksutapa.tunniste = this.annaSeuraavaMaksutavanNumero(vanhat)
      vanhat.push(uusiMaksutapa)
    }
    this._maksutavatChangedSubject.next()
  }

  lisaaMaksutapa() {
    this._kaikkiKirjanpitotilitObservable.pipe(
      take(1)
    ).subscribe(tilit => {
      const seuraavaNumero = this.annaSeuraavaMaksutavanNumero(this.maksutavatJaKayttajat)
      const lisattava: AsiakkaanMaksutapa = {
        avain: null,
        aktiivinen: true,
        // eslint-disable-next-line max-len
        base64Kuva: this._defaultMaksutapaKuva,
        nimi: '',
        tunniste: seuraavaNumero,
        kirjauksienLuontityyppi: null,
        sahkoisenTiliotteenTunniste: null,
        oletusVastatili: null,
        tilitapahtumatSynkronoidaanAlkaen: null,
        tilitapahtumatSynkronoidaanLoppuen: null
      }

      const maksutapaJaKayttajat: MaksutapaJaKayttajat = {
        kayttajat: [],
        maksutapa: lisattava,
        uusi: true,
        onkoValtuutus: null
      }

      this.maksutavatJaKayttajat.push(maksutapaJaKayttajat)
      const group = this._annaMaksutapaFormGroup(maksutapaJaKayttajat, tilit)
      this.maksutavatArray.push(group)
    })
  }

  private customMaksutapatunnisteet: number[] = [
    MAKSUTAPA_MYYNTILASKU.tunniste,
    MAKSUTAPA_PALKKATOSITE.tunniste,
    MAKSUTAPA_MYYNTITOSITE.tunniste,
    MAKSUTAPA_TILIOTETOSITE.tunniste,
    MAKSUTAPA_MUU.tunniste,
    MAKSUTAPA_SAHKOISET_LASKUT.tunniste,
    MAKSUTAPA_SAHKOISET_LASKUT_FAKE.tunniste
  ]

  private annaSeuraavaMaksutavanNumero(maksutavatJaKayttajat: MaksutapaJaKayttajat[]): number {
    let suurin = 0
    if (maksutavatJaKayttajat) {

      for (const maksutapaJaKayttajat of maksutavatJaKayttajat) {
        if (
          !this.customMaksutapatunnisteet.includes(maksutapaJaKayttajat.maksutapa.tunniste) &&
          maksutapaJaKayttajat.maksutapa.tunniste > suurin
        ) {
          suurin = maksutapaJaKayttajat.maksutapa.tunniste
        }
      }

      suurin++

      while (this.customMaksutapatunnisteet.includes(suurin)) {
        suurin++
      }

    }
    return suurin
  }

  private _lisaaMaksutavatJaOletus(maksutavat: MaksutapaJaKayttajat[], kirjanpitotilit: Kirjanpitotili[]) {
    // Tyhjennä array
    this.maksutavatArray.clear()

    // Lisää kaikki
    for (const maksutapa of maksutavat) {
      if (maksutapa.maksutapa.holviOmallaRahallaAccount) {
        this.holviOmallaRahallaTili = maksutapa.maksutapa.tunniste
      }

      const group = this._annaMaksutapaFormGroup(maksutapa, kirjanpitotilit)
      this.maksutavatArray.push(group)
    }
  }

  compareLuontityypit(tyyppi1: LokalisoituTiliotetyyppi, tyyppi2: LokalisoituTiliotetyyppi): boolean {
    return tyyppi1?.tunniste === tyyppi2?.tunniste && tyyppi1?.alitunniste === tyyppi2?.alitunniste
  }

  private _annaMaksutapaFormGroup(maksutapa: MaksutapaJaKayttajat, kirjanpitotilit: Kirjanpitotili[]): FormGroup<MaksutapaFormGroup> {

    const tallennettuIbanMaksutapa = maksutapa.maksutapa.sahkoisenTiliotteenTunniste &&
      [KirjauksienLuontityyppi.SAHKOINEN_TILIOTE, KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE].includes(maksutapa.maksutapa.kirjauksienLuontityyppi)
    const tilinumeroDisabled = !(maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE || maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE)
    const tilitapahtumatSynkronoidaanAikaDisabled = !(maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE || maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE || maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE)
    const vastakirjausDisabled = maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE

    const luontityyppiKey: string = maksutapa.maksutapa.kirjauksienLuontityyppi + '_' + (maksutapa.maksutapa.sahkoisenTiliotteenAlatyyppi || null)
    const luontityyppi = this.kirjaustenLuontityypitByTunnisteetMap.get(luontityyppiKey)

    const kirjaustenLuontityyppiControl = new FormControl<LokalisoituTiliotetyyppi>({ value: luontityyppi, disabled: tallennettuIbanMaksutapa }, [Validators.required])
    const oletusVastatiliControl = new FormControl<string>({ value: maksutapa.maksutapa.oletusVastatili, disabled: vastakirjausDisabled || tallennettuIbanMaksutapa }, vastakirjausDisabled ? [this._validateVastatili] : [this._validateVastatili, Validators.required])
    const sahkoisenTiliotteenTunnisteControl = new FormControl<string>({ value: maksutapa.maksutapa.sahkoisenTiliotteenTunniste, disabled: tilinumeroDisabled || tallennettuIbanMaksutapa }, tilinumeroDisabled ? [this._validateIban] : [this._validateIban, Validators.required])
    const tilitapahtumatSynkronoidaanAlkaenControl = new FormControl<Date>({ value: this._dateService.localDateToDate(maksutapa.maksutapa.tilitapahtumatSynkronoidaanAlkaen), disabled: tilitapahtumatSynkronoidaanAikaDisabled }, tilitapahtumatSynkronoidaanAikaDisabled ? [] : [Validators.required])
    const tilitapahtumatSynkronoidaanLoppuenControl = new FormControl<Date>({ value: this._dateService.localDateToDate(maksutapa.maksutapa.tilitapahtumatSynkronoidaanLoppuen), disabled: tilitapahtumatSynkronoidaanAikaDisabled }, [])
    const omallaRahallaUidControl = new FormControl<AsiakkaanMaksutapa['omallaRahallaUid']>({
      value: maksutapa?.maksutapa?.omallaRahallaUid || null,
      disabled: true // maksutapa.maksutapa.kirjauksienLuontityyppi !== KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE  // TODO! Uncomment once omaRahallaUid logic is tested
    }, [])

    const maksutapaForm = new FormGroup<MaksutapaFormGroup>({
      nimi: new FormControl<string>(maksutapa.maksutapa.nimi, [Validators.required]),
      kirjaustenLuontityyppi: kirjaustenLuontityyppiControl,
      sahkoisenTiliotteenTunniste: sahkoisenTiliotteenTunnisteControl,
      oletusVastatili: oletusVastatiliControl,
      aktiivinen: new FormControl<boolean>(maksutapa.maksutapa.aktiivinen, []),
      tilitapahtumatSynkronoidaanAlkaen: tilitapahtumatSynkronoidaanAlkaenControl,
      tilitapahtumatSynkronoidaanLoppuen: tilitapahtumatSynkronoidaanLoppuenControl,
      omallaRahallaUid: omallaRahallaUidControl
    })

    maksutapaForm.get('nimi').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      maksutapa.maksutapa.nimi = this._validationService.processValue(value)
    })

    kirjaustenLuontityyppiControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe((value: LokalisoituTiliotetyyppi) => {
      maksutapa.maksutapa.kirjauksienLuontityyppi = value?.tunniste || null
      const alatyyppi = value?.alitunniste || null


      if (alatyyppi) {
        maksutapa.maksutapa.sahkoisenTiliotteenAlatyyppi = alatyyppi
      } else {
        delete maksutapa.maksutapa.sahkoisenTiliotteenAlatyyppi
      }

      if (
        maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.SAHKOINEN_TILIOTE ||
        maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.MAKSUKORTIN_SAHKOINEN_TILIOTE
      ) {

        sahkoisenTiliotteenTunnisteControl.setValidators([this._validateIban, Validators.required])
        sahkoisenTiliotteenTunnisteControl.enable()

        tilitapahtumatSynkronoidaanAlkaenControl.setValidators([Validators.required])
        tilitapahtumatSynkronoidaanAlkaenControl.enable()

        tilitapahtumatSynkronoidaanLoppuenControl.enable()

      } else if (
        maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.HOLVI_SAHKOINEN_TILIOTE
      ) {

        tilitapahtumatSynkronoidaanAlkaenControl.setValidators([Validators.required])
        tilitapahtumatSynkronoidaanAlkaenControl.enable()

        sahkoisenTiliotteenTunnisteControl.setValue(null)
        sahkoisenTiliotteenTunnisteControl.setValidators([])
        sahkoisenTiliotteenTunnisteControl.disable()

        tilitapahtumatSynkronoidaanLoppuenControl.enable()
      } else {
        tilitapahtumatSynkronoidaanAlkaenControl.setValue(null)
        tilitapahtumatSynkronoidaanAlkaenControl.setValidators([])
        tilitapahtumatSynkronoidaanAlkaenControl.disable()

        sahkoisenTiliotteenTunnisteControl.setValue(null)
        sahkoisenTiliotteenTunnisteControl.setValidators([])
        sahkoisenTiliotteenTunnisteControl.disable()

        tilitapahtumatSynkronoidaanLoppuenControl.setValue(null)
        tilitapahtumatSynkronoidaanLoppuenControl.disable()
      }

      // if (maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.TOSITTEISTA_GENEROITAVA_TILIOTE) {
      //   omallaRahallaUidControl.enable() // TODO! Uncomment once tested
      // }

      if (maksutapa.maksutapa.kirjauksienLuontityyppi === KirjauksienLuontityyppi.KASIN_SYOTETTAVA_TILIOTE) {
        oletusVastatiliControl.setValue(null)
        oletusVastatiliControl.setValidators([])
        oletusVastatiliControl.disable()
      } else {
        oletusVastatiliControl.setValidators([this._validateVastatili, Validators.required])
        oletusVastatiliControl.enable()
      }

      this._maksutavatChangedSubject.next()

    })

    sahkoisenTiliotteenTunnisteControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).pipe(
      withLatestFrom(this.asiakkaanIbanitJaPankitObservable)
    ).subscribe(([value, ibanitJaPankit]) => {
      maksutapa.maksutapa.sahkoisenTiliotteenTunniste = this._stringService.removeAllWhiteSpaces(value)
      maksutapa.onkoValtuutus = !!ibanitJaPankit?.length && ibanitJaPankit.map(ijp => ijp.iban).includes(maksutapa.maksutapa.sahkoisenTiliotteenTunniste)
    })

    this.asiakkaanIbanitJaPankitObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(ibanitJaPankit => {
      maksutapa.onkoValtuutus = !!ibanitJaPankit?.length && ibanitJaPankit.map(ijp => ijp.iban).includes(maksutapa.maksutapa.sahkoisenTiliotteenTunniste)
    })

    maksutapaForm.get('aktiivinen').valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      maksutapa.maksutapa.aktiivinen = this._validationService.processValue(value)
    })

    tilitapahtumatSynkronoidaanAlkaenControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      maksutapa.maksutapa.tilitapahtumatSynkronoidaanAlkaen = this._dateService.dateToLocalDate(value)
    })

    tilitapahtumatSynkronoidaanLoppuenControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      maksutapa.maksutapa.tilitapahtumatSynkronoidaanLoppuen = this._dateService.dateToLocalDate(value)
    })

    omallaRahallaUidControl.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      if (value) {
        maksutapa.maksutapa.omallaRahallaUid = value
      } else {
        delete maksutapa.maksutapa.omallaRahallaUid
      }
    })

    oletusVastatiliControl.valueChanges.pipe(
      debounceTime(150),
      takeUntil(this._ngUnsubscribe)
    ).subscribe((value: any) => {
      if (value && value.numero) { // Huom, objekti tunnistetaan numerosta!
        maksutapa.maksutapa.oletusVastatili = value.numero
      } else if (value) {
        let setNull = true
        const trimmattu = value.trim()
        for (const tili of kirjanpitotilit) {
          if (tili.numero === trimmattu) {
            maksutapa.maksutapa.oletusVastatili = tili.numero
            setNull = false
            break
          }
        }
        if (setNull) {
          maksutapa.maksutapa.oletusVastatili = null
        }
      } else {
        maksutapa.maksutapa.oletusVastatili = null
      }
    })

    oletusVastatiliControl.valueChanges.pipe(
      debounceTime(50),
      startWith<string | Kirjanpitotili>(''),
      map(value => {
        if (typeof value === 'string') {
          return value
        }
        if (value) {
          return value.numero
        }
        return null
      }),
      map(name => name ? this._filterTili(name, kirjanpitotilit) : []),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this.suodatetutKirjanpitotilitSubject.next(value)
    })



    return maksutapaForm
  }

  lisaaMaksutavanKuva(index: number) {
    this._lisaaKuvaIndeksiin = index
    const dialogRef = this._dialog.open<AsiakkaanMaksutavatValitseKuvakeDialog, void, string>(AsiakkaanMaksutavatValitseKuvakeDialog)
    dialogRef.afterClosed().pipe(take(1)).subscribe((valittu) => {
      if (valittu) {
        this.maksutavatJaKayttajat[this._lisaaKuvaIndeksiin].maksutapa.base64Kuva = valittu
      }
    })
  }

  holviOmallaRahallaTiliChanged(val: number) {
    if (val === this.holviOmallaRahallaTili) {
      this.holviOmallaRahallaTili = null
    } else {
      this.holviOmallaRahallaTili = val
    }
    this.commonError = null
  }

  private _filterTili(numeroTaiNimi: string, kirjanpitotilit: Kirjanpitotili[]): Kirjanpitotili[] {
    const filterValue = numeroTaiNimi.toLowerCase()
    return kirjanpitotilit.filter(tili => tili.numero.toLowerCase().indexOf(filterValue) === 0 || this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tili, 'fi').toLowerCase().indexOf(filterValue) === 0)
  }
  private _validateVastatili: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._sallitutKirjanpitotilinumerot.has(ctrl.value)) {
      return { notkirjanpitotili: true }
    }
  }
  private _validateIban: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
    if (ctrl.value == null || ctrl.value === undefined || ctrl.value.trim() === '') {
      return null
    }
    if (!this._ibanService.isValidIban(ctrl.value)) {
      return { invalidiban: true }
    }
  }

  displayTiliFn(tili?: Kirjanpitotili): string | undefined {
    return tili ? tili.numero + ' ' + this._tilikarttaJaettuService.annaKirjanpitotilinNimi(tili, 'fi') : undefined
  }

  async save() {

    if (!this.form.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    const maksutavat = this.maksutavatJaKayttajat.map(a => a.maksutapa)
    const holviApiMaksutavat = maksutavat.filter(mt => mt.sahkoisenTiliotteenAlatyyppi === SahkoisenTiliotteenAlatyyppi.HOLVI_API)

    if (holviApiMaksutavat.length && !this.holviOmallaRahallaTili) {
      this.commonError = 'Valitse maksutapa, jossa "Kirjauksien luontitapa" on "Luodaan tositteista", johon laitetaan Holvista tulevat ulkopuoliset tositteet.'
      return
    }

    if (maksutavat.filter(m => !m.base64Kuva || m.base64Kuva === this._defaultMaksutapaKuva).length > 0) {
      this.commonError = 'Valitse kaikille maksutavoille kuvake napauttamalla maksutavan kuvaketta.'
      return
    }

    if (!!this.saveStarted) {
      return
    }
    this.saveStarted = true
    this.commonError = null

    this._ladataanService.aloitaLataaminen()

    const ibanitJaPankit = await firstValueFrom(this.asiakkaanIbanitJaPankitObservable)
    const connectedIbans = ibanitJaPankit.map(ijp => ijp.iban)

    for (const maksutapa of maksutavat) {
      if (maksutapa.tunniste === this.holviOmallaRahallaTili) {
        maksutapa.holviOmallaRahallaAccount = true
      } else {
        delete maksutapa.holviOmallaRahallaAccount
      }
    }

    const areAllAccessibleAccountsInMaksutavat: boolean = this._areAllConnectedIbansSetToMaksutavat(connectedIbans, maksutavat)

    return this._asiakasService.tallennaMaksutavat(this.asiakas, maksutavat, areAllAccessibleAccountsInMaksutavat).then(() => {
      this._snackBar.open('Maksutavat tallennettiin.', 'OK', { duration: 2500, verticalPosition: 'top' })
    }).catch(err => {
      this._errorHandler.handleError(err)
      this.commonError = 'Tallentamisen aikana tapahtui virhe. Ole hyvä ja ilmoita tästä ylläpidolle. Tekninen virhe: "' + err.message + '".'
    }).finally(() => {
      this._ladataanService.lopetaLataaminen()
      this.saveStarted = false
    })
  }
  private _areAllConnectedIbansSetToMaksutavat(connectedIbans: string[], maksutavat: AsiakkaanMaksutapa[]): boolean {
    const maksutapojenTilit = maksutavat.map(mt => mt.sahkoisenTiliotteenTunniste)
    for (const connected of connectedIbans) {
      if (!maksutapojenTilit.includes(connected)) {
        return false
      }
    }
    return true
  }

  async disableConnectedAccount(pankkiJaIban: PankkiJaIban) {
    this.disableOrEnableConnectedAccount(pankkiJaIban, 'disable')
  }

  enableConnectedAccount(pankkiJaIban: PankkiJaIban) {
    this.disableOrEnableConnectedAccount(pankkiJaIban, 'activate')
  }

  private async disableOrEnableConnectedAccount(pankkiJaIban: PankkiJaIban, action: 'disable' | 'activate') {
    this._ladataanService.aloitaLataaminen()

    try {
      const requestData: BankConnectionDisableRequest = {
        asiakasAvain: this.asiakas.avain,
        iban: pankkiJaIban.iban,
        action: action
      }
      const resp = await this._firebaseLemonator.functionsCall<BankConnectionDisableRequest, BankConnectionDisableResponse>('bankConnectionDisableOrActivateManually', requestData)

      if (!resp || resp.e) {
        throw new Error(resp.e ?? 'No response')
      }

      // Don't do anything if successful - the BankConnection will be updated in the observable chain.

    } catch (err) {
      this._errorHandler.handleError(new Error('Failed to disable bank connection manually: ' + err?.message))
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private _maksuConfigAlkaaEnnenLoppuaValidator(maksuConfigGroup: FormGroup<MaksuConfigFormGroup>): (control: FormControl<Date>) => ValidationErrors {
    return (control: FormControl<Date>): ValidationErrors | null => {
      const l = maksuConfigGroup.get('end')?.value
      const a = maksuConfigGroup.get('start')?.value
      if (l && a && a >= l) {
        return { 'loppuEnnenAlkua': true }
      }
      return null
    }
  }

  private _annaFormGroupMaksuConfigille(config: LemonatorPaymentConfig, focus: boolean, lukitseVanhat: boolean): FormGroup<MaksuConfigFormGroup> {

    const lukossa = lukitseVanhat

    const debtorIdentifierControl = new FormControl<string>({ value: config.debtorIdentifier, disabled: lukossa }, [Validators.required])
    const startControl = new FormControl<Date>({ value: this._dateService.numberToDate(config.start), disabled: lukossa }, [Validators.required])
    const endControl = new FormControl<Date>({ value: this._dateService.numberToDate(config.end), disabled: lukossa }, [])
    const ibanControl = new FormControl<string>({ value: config.iban, disabled: lukossa }, [Validators.required, this._validateIban])

    const configGroup = new FormGroup<MaksuConfigFormGroup>({
      'debtorIdentifier': debtorIdentifierControl,
      'start': startControl,
      'end': endControl,
      'iban': ibanControl
    })

    startControl.setValidators([Validators.required, this._maksuConfigAlkaaEnnenLoppuaValidator(configGroup), Validators.required])
    endControl.setValidators([this._maksuConfigAlkaaEnnenLoppuaValidator(configGroup)])

    configGroup.get('debtorIdentifier').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      config.debtorIdentifier = val
    })

    configGroup.get('iban').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(val => {
      config.iban = val
    })

    configGroup.get('start').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      config.start = this._dateService.dateToNumber(value)
    })

    configGroup.get('end').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(value => {
      config.end = this._dateService.dateToNumber(value)
    })

    if (focus) {
      setTimeout(() => {
        if (this.debtorIdentifierit?.last) {
          this.debtorIdentifierit.last.focus()
          this._changeDetectorRef.markForCheck()
        }
      }, 10)
    }

    return configGroup

  }

  lisaaMaksuConfig() {
    const formArray = this.maksuConfigFormArray

    const config: LemonatorPaymentConfig = {
      avain: this._firebaseLemonator.firestoreCreateId(),
      debtorIdentifier: null,
      end: null,
      start: this._dateService.currentNumberDate(),
      iban: null,
      type: 'avalo'
    }
    const configGroup = this._annaFormGroupMaksuConfigille(config, true, false)

    if (!this.asiakas.paymentConfigs) {
      this.asiakas.paymentConfigs = []
    }
    this.asiakas.paymentConfigs.push(config)
    formArray.push(configGroup)
  }

  async saveMaksuConfigs() {

    this.commonErrorPaymentConfigs = null

    if (!this.asiakas.paymentConfigs || this.asiakas.paymentConfigs.length < 1) {
      this.commonErrorPaymentConfigs = 'Lisää vähintään yksi maksutili.'
      return
    }

    if (!this.maksuConfigForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.maksuConfigForm)
      this._validationService.naytaEnsimmainenVirhe()
      return
    }

    this._ladataanService.aloitaLataaminen()

    this._asiakasService.paivitaAsiakas(this.asiakas, 'tilikaudet-eivat-voineet-muuttua', false, false).then(() => {
      this._asiakasService.asetaNykyinenAsiakas(this.asiakas)
      this._ladataanService.lopetaLataaminen()
      this._snackBar.open('Asiakas tallennettiin onnistuneesti.', 'OK', { duration: 5000, verticalPosition: 'top' })
    }).catch(err => {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(err)
    })

  }

  private _alustaMaksuConfigLomakkeenTiedot(asiakas: Asiakas) {
    this.maksuConfigForm.get('hyvaksyjienLukumaara').setValue(asiakas.paymentsNumberOfApprovalsRequired ?? null)

    const formArray = this.maksuConfigFormArray
    formArray.clear()
    for (const config of asiakas.paymentConfigs ?? []) {
      const configGroup = this._annaFormGroupMaksuConfigille(config, false, false)
      formArray.push(configGroup)
    }
  }

  get maksuConfigControllit(): FormGroup<MaksuConfigFormGroup>[] {
    return this.maksuConfigFormArray.controls as FormGroup<MaksuConfigFormGroup>[]
  }

  get maksuConfigFormArray(): FormArray<FormGroup<MaksuConfigFormGroup>> {
    return this.maksuConfigForm.get('configs') as FormArray<FormGroup<MaksuConfigFormGroup>>
  }

}
